import { getJourneyInvoice, getPayWindowInvoice } from 'api';
import {
  DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE,
  DOWNLOAD_RECEIPT_OR_INVOICE_ERROR,
  DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS,
} from 'pages/appRoutesConst';
import { useNavigate } from 'react-router';
const { setTimeout, clearTimeout } = window;
import { useSnackbar } from 'notistack';

interface UseInvoices {
  payWindowId?: string;
  journeyId?: string;
  type: 'private' | 'domestic' | 'foreign';
}
interface UseInvoices {
  payWindowId?: string;
  journeyId?: string;
  type: 'private' | 'domestic' | 'foreign';
}

export const useInvoices = ({
  payWindowId,
  journeyId,
  type,
}: UseInvoices) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let timeoutId: number;

  const onSubmit = async (fields: Record<string, unknown>) => {
    const contactingMsg = enqueueSnackbar(
      'Contacting the server, please wait...',
      {
        variant: 'info',
        autoHideDuration: 9000,
      },
    );

    timeoutId = setTimeout(() => {
      navigate(DOWNLOAD_RECEIPT_OR_INVOICE_ERROR);
    }, 10000);

    if (payWindowId) {
      const result = await getPayWindowInvoice(
        payWindowId,
        type,
        fields,
      );
      if (result instanceof Error) {
        if (Array.isArray(result.response?.data?.errors)) {
          result.response?.data?.errors.forEach((message: string) => {
            enqueueSnackbar(message, {
              variant: 'error',
            });
          });
        }
        if (result.response?.data?.errorMessage) {
          enqueueSnackbar(result.response?.data?.errorMessage, {
            variant: 'error',
          });
        }
        clearTimeout(timeoutId);
        closeSnackbar(contactingMsg);
        navigate(DOWNLOAD_RECEIPT_OR_INVOICE_ERROR);
        return;
      }
      if (result) {
        clearTimeout(timeoutId);
        closeSnackbar(contactingMsg);
        if (result.status === 200) {
          navigate(DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE);
          if (Array.isArray(result?.data?.errors)) {
            result.data?.errors.forEach((message: string) => {
              enqueueSnackbar(message, {
                variant: 'error',
              });
            });
          }
        }
        if (result.status === 201)
          navigate(DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS);
      }
      return;
    }
    if (journeyId) {
      const result = await getJourneyInvoice(journeyId, type, fields);
      if (result instanceof Error) {
        if (Array.isArray(result.response?.data?.errors)) {
          result.response?.data?.errors.forEach((message: string) => {
            enqueueSnackbar(message, {
              variant: 'error',
            });
          });
        }
        if (result.response?.data?.errorMessage) {
          enqueueSnackbar(result.response?.data?.errorMessage, {
            variant: 'error',
          });
        }
        clearTimeout(timeoutId);
        closeSnackbar(contactingMsg);
        navigate(DOWNLOAD_RECEIPT_OR_INVOICE_ERROR);
        return;
      }
      if (result) {
        clearTimeout(timeoutId);
        closeSnackbar(contactingMsg);
        if (result.status === 200) {
          navigate(DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE);
          if (Array.isArray(result?.data?.errors)) {
            result.data?.errors.forEach((message: string) => {
              enqueueSnackbar(message, {
                variant: 'error',
              });
            });
          }
          if (result?.data?.errorMessage) {
            enqueueSnackbar(result?.data?.errorMessage, {
              variant: 'error',
            });
          }
        }
        if (result.status === 201)
          navigate(DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS);
      }
    }
    return;
  };

  return {
    onSubmit,
  };
};
