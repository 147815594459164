import CheckIcon from '@mui/icons-material/Check';
import {
  styled,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';

export interface Props extends CheckboxProps {
  errorText?: string;
  label: string;
}

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  alignItems: 'center',
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
  },
  '& .MuiCheckbox-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Check = styled('span', {
  shouldForwardProp: (prop) => prop !== 'checked' && prop !== 'error',
})<{
  checked?: boolean;
  error?: boolean;
}>(({ checked, error }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  borderRadius: 4,
  border: `1px solid ${
    error ? COLORS.RED.MAIN : COLORS.DARK_BLUE.MAIN
  }`,
  backgroundColor: checked ? COLORS.DARK_BLUE.MAIN : COLORS.WHITE,
}));

const CheckIconStyled = styled(CheckIcon)(() => ({
  fill: COLORS.WHITE,
  fontSize: 20,
}));

const Checkbox = ({ label, errorText, ...rest }: Props) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'components.formFields',
  });

  return (
    <>
      <FormControlLabelStyled
        control={
          <MuiCheckbox
            icon={<Check error={!!errorText} />}
            checkedIcon={
              <Check checked>
                <CheckIconStyled />
              </Check>
            }
            checked={!!rest.value}
            {...rest}
          />
        }
        label={label}
      />
      {errorText && (
        <div>
          <Typography color="error">{t(errorText)}</Typography>
        </div>
      )}
    </>
  );
};

export default Checkbox;
