import { useEffect, useState } from 'react';
import { Country } from 'types/appTypes';
import { UseFormTrigger } from 'react-hook-form';

interface UsePostalCode {
  countries: Country[];
}

export const usePostalCode = ({ countries }: UsePostalCode) => {
  const [postalRegex, setPostalRegex] = useState<
    RegExp | undefined
  >();

  const handleCountryChange = (value: string) => {
    const newRegex = countries.find(
      ({ code }) => code === value,
    )?.postal_code_regex;
    if (newRegex) {
      setPostalRegex(new RegExp(newRegex));
    }
  };

  return {
    postalRegex,
    handleCountryChange,
  };
};

interface UsePostalCodeValidation {
  postalCodeValue: string;
  postalCodeName: string;
  trigger: UseFormTrigger<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  postalRegex?: RegExp;
}

export const usePostalCodeValidation = ({
  postalCodeValue,
  postalCodeName,
  trigger,
  postalRegex,
}: UsePostalCodeValidation) => {
  useEffect(() => {
    const postalCodeHasValue = postalCodeValue;
    if (postalCodeHasValue) {
      trigger(postalCodeName); // validate postal code if postal code regex has changed and it has a value
    }
  }, [postalRegex]);
};
