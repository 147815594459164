import { Device } from '../../types/appTypes';
import { useTranslation } from 'react-i18next';
import { MenuItem, useTheme } from '@mui/material';
import * as yup from 'yup';
import { saveEditDevice } from '../../api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppTextField from '../form/textField';
import AppSelect from '../form/select';
import DialogActions from '@mui/material/DialogActions';
import AppButton from '../appButton';
import { useEffect } from 'react';

export type FormValues = {
  name: string;
  type: string;
};

// Dialog to edit device with React-hook-form and yup implementation.

const EditDeviceDialog: React.FC<{
  device: Device;
  isOpen: boolean;
  closeFunction: () => void;
  saveFunction: (data: Device) => void;
}> = ({ device, isOpen, closeFunction, saveFunction }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  // Define validation rules here
  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      type: yup.string().required(),
    })
    .required();

  const checkIfRequired = (fieldName: string) =>
    schema.fields[fieldName]?.exclusiveTests?.required;

  const devicesTypeList = [
    { name: t(`pages.device.type.Card`), value: 'Card' },
    { name: t(`pages.device.type.Smartphone`), value: 'Smartphone' },
    { name: t(`pages.device.type.Smartwatch`), value: 'Smartwatch' },
  ];
  // Define submit function
  const onSubmit = async (data: FormValues) => {
    const updatedDevice = await saveEditDevice(device.id, data);
    if (updatedDevice) {
      saveFunction(updatedDevice);
    }
  };
  // useForm accept object with default values and validation schema
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: device.name,
      type: device.type,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        name: device.name,
        type: device.type,
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeFunction}
      aria-labelledby="edit-device-dialog-title"
      aria-describedby="edit-device-dialog-description"
    >
      <Box
        sx={{
          maxWidth: '400px',
          margin: '0 auto',
        }}
      >
        <DialogTitle id="edit-device-dialog-title">
          {t('components.modals.editDevice.title')}
          <IconButton
            aria-label="close"
            onClick={closeFunction}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.text.lightGrey.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText
              id="edit-device-dialog-description"
              sx={{
                color: theme.palette.secondary.main,
                marginBottom: theme.spacing(3),
              }}
            >
              {t('components.modals.editDevice.text')}
            </DialogContentText>
            <Box
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              <Controller
                control={control}
                name="name"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <AppTextField
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                    name="name"
                    multiline
                    required={checkIfRequired('name')}
                    label={t('components.modals.editDevice.name')}
                    error={!!error?.message}
                    errorText={error?.message}
                    fontWeight={700}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                control={control}
                name="type"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <AppSelect
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                    name="type"
                    multiline
                    required={checkIfRequired('type')}
                    label={t('components.modals.editDevice.type')}
                    error={!!error?.message}
                    errorText={error?.message}
                    renderValue={(selected) =>
                      devicesTypeList.find(
                        (deviceType) => deviceType.value === selected,
                      )?.name ?? <>{selected}</>
                    }
                    fontWeight={700}
                  >
                    {devicesTypeList.map((deviceType, index) => (
                      <MenuItem key={index} value={deviceType.value}>
                        {deviceType.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              padding: theme.spacing(1, 3, 3),
            }}
          >
            <AppButton
              color="secondary"
              variant="contained"
              type="submit"
              autoFocus
            >
              {t('components.modals.editDevice.buttonSave')}
            </AppButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default EditDeviceDialog;
