import { CircularProgress, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import loadingLogo from 'assets/images/switchio_loading_logo.svg';
import masterCardSwitchio from 'assets/images/mastercard-switchio.svg';

const Layout = styled('div')(({ theme: { palette } }) => ({
  background: palette.background.lightGrey.main,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const Footer = styled('div')(({ theme: { palette, spacing } }) => ({
  background: palette.common.white,
  height: spacing(24),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Content = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ImgWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginLeft: spacing(2),
  marginRight: spacing(2),
}));

const Loading = () => {
  const theme = useTheme();
  return (
    <Layout>
      <Content>
        <ImgWrapper>
          <img style={{ maxWidth: '100%' }} src={loadingLogo} />
        </ImgWrapper>
        <div>
          <CircularProgress
            sx={{
              mt: 10,
              '&.MuiCircularProgress-root': {
                color: theme.palette.grey[500],
              },
            }}
          />
        </div>
      </Content>
      <Footer>
        <ImgWrapper>
          <img
            style={{ maxWidth: '100%' }}
            src={masterCardSwitchio}
          />
        </ImgWrapper>
      </Footer>
    </Layout>
  );
};

export default Loading;
