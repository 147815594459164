import { styled } from '@mui/material';

const Image = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));

interface AppImageProps {
  path: string;
}

const rootUrl = process.env.REACT_APP_API_KEY;

// Application Image wrapper
const AppImage: React.FC<AppImageProps> = ({ path }) => (
  <Image src={`${rootUrl}/images/${path}`} />
);

export default AppImage;
