import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem, styled } from '@mui/material';
import AppButton from 'components/appButton';
import SelectField from 'components/form/selectField';
import TextField from 'components/form/textFieldWithController';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputVariant } from 'types/appEnums';
import { Country } from 'types/appTypes';
import { useInvoices } from 'utils/hooks/useInvoices';
import {
  REGEX_NAME,
  REGEX_CITY,
  REGEX_STREET,
  REGEX_EMAIL,
} from 'types/regex';
import {
  usePostalCode,
  usePostalCodeValidation,
} from 'utils/hooks/usePostalCode';
import * as yup from 'yup';

interface Props {
  payWindowId?: string;
  journeyId?: string;
  countries: Country[];
}

const NAME = 'name';
const COUNTRY = 'country';
const POSTAL_CODE = 'postal_code';
const CITY = 'city';
const STREET = 'street';
const EMAIL = 'email';

// default regex to match everything
const schema = (regex = new RegExp('.*')) => {
  return yup
    .object()
    .shape({
      [NAME]: yup
        .string()
        .trim()
        .max(70)
        .required('required')
        .matches(REGEX_NAME, 'allowedChar'),
      [COUNTRY]: yup.string().trim().required('required'),
      [POSTAL_CODE]: yup
        .string()
        .trim()
        .required('required')
        .max(10)
        .matches(regex, 'zip_regex_error'),
      [CITY]: yup
        .string()
        .trim()
        .max(35)
        .required('required')
        .matches(REGEX_CITY, 'allowedChar'),
      [STREET]: yup
        .string()
        .trim()
        .max(35)
        .required('required')
        .matches(REGEX_STREET, 'allowedChar'),
      [EMAIL]: yup
        .string()
        .trim()
        .max(70)
        .required('required')
        .matches(REGEX_EMAIL, 'email_regex_error'),
    })
    .required();
};

const GridWrapper = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingLeft: spacing(16),
    paddingRight: spacing(16),
    [breakpoints.down('md')]: {
      paddingLeft: spacing(8),
      paddingRight: spacing(8),
    },
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

const ContinueButtonWrapper = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginTop: spacing(5.5),
    textAlign: 'right',
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
    },
  }),
);

const PrivatePersonTab = ({
  journeyId,
  payWindowId,
  countries,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { postalRegex, handleCountryChange } = usePostalCode({
    countries,
  });
  const { control, handleSubmit, trigger, watch } = useForm({
    resolver: yupResolver(schema(postalRegex)),
    defaultValues: {
      [NAME]: '',
      [COUNTRY]: '',
      [POSTAL_CODE]: '',
      [CITY]: '',
      [STREET]: '',
      [EMAIL]: '',
    },
    mode: 'onBlur',
  });
  usePostalCodeValidation({
    postalCodeValue: watch(POSTAL_CODE),
    postalCodeName: POSTAL_CODE,
    trigger,
    postalRegex,
  });
  const { onSubmit } = useInvoices({
    payWindowId,
    journeyId,
    type: 'private',
  });

  return (
    <GridWrapper container rowSpacing={2.5} columnSpacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={NAME}
          inputProps={{ maxLength: 70 }}
          label={t('components.formFields.name')}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          control={control}
          name={COUNTRY}
          label={t('components.formFields.country')}
          required
          variant="outlined"
          inputVariant={InputVariant.LIGHT}
          customOnChange={handleCountryChange}
          MenuProps={{
            sx: {
              maxHeight: 250,
            },
          }}
          renderValue={(selected) =>
            countries.find((value) => value.code === selected)?.[
              resolvedLanguage as keyof Country
            ] ?? <>{selected}</>
          }
        >
          {countries.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {value[resolvedLanguage as keyof Country] ?? ''}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={POSTAL_CODE}
          inputProps={{ maxLength: 10 }}
          label={t('components.formFields.zip')}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={CITY}
          label={t('components.formFields.city')}
          inputProps={{ maxLength: 35 }}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={STREET}
          label={t('components.formFields.address')}
          inputProps={{ maxLength: 35 }}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={EMAIL}
          label={t('components.formFields.email')}
          inputProps={{ maxLength: 70 }}
          required
        />
      </Grid>
      <ContinueButtonWrapper
        item
        xs={12}
        md={12}
        alignItems="flex-end"
      >
        <AppButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit(onSubmit)}
        >
          {t('buttons.continue')}
        </AppButton>
      </ContinueButtonWrapper>
    </GridWrapper>
  );
};

export default PrivatePersonTab;
