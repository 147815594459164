// AUTHENTICATED ROUTES
export const ROOT_URL = '/';
export const FAQ_URL = '/faq';
export const CONTACTS_URL = '/contacts';
export const CONTACT_DETAIL_URL = '/contact';
export const RIDES_URL = '/journeys';
export const HELP_URL = '/help';
export const DEVICES_URL = '/devices';
export const DEVICES_DETAIL_URL = '/device';
export const DOWNLOAD_RECEIPT_OR_INVOICE_PAY_WINDOW =
  '/download-receipt-or-invoice/pay-window';
export const DOWNLOAD_RECEIPT_OR_INVOICE_JOURNEY =
  '/download-receipt-or-invoice/journey';
export const DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS =
  '/download-receipt-or-invoice/success';
export const DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE =
  '/download-receipt-or-invoice/duplicate';
export const DOWNLOAD_RECEIPT_OR_INVOICE_ERROR =
  '/download-receipt-or-invoice/error';

// NOT AUTHENTICATED ROUTES
export const LOGIN_URL = '/login';
export const LOADING_URL = '/loading';
export const HEALTH_CHECK_URL = '/health-check';
