import { useEffect } from 'react';
import { EVENTS } from 'utils/funcs/errorHandling';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Event handler which catches event from whole app. It is used in App.tsx
export const EventHandler: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common', {
    keyPrefix: 'components.eventHandler',
  });

  const handleEvent = (event: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.detail.name === EVENTS['UNAUTHORIZED']) {
      enqueueSnackbar(t('401Error'), { variant: 'error' });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
    } else if (event.detail.name === EVENTS['SERVER_ERROR']) {
      enqueueSnackbar(t('5xxError'), { variant: 'error' });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
    } else if (event.detail.name === EVENTS['UNKNOWN_ERROR']) {
      enqueueSnackbar(t('unknownError'), { variant: 'error' });
    }
  };

  useEffect(() => {
    document.addEventListener('build', (event) => handleEvent(event));

    return () => {
      document.removeEventListener(
        'build',
        (event) => handleEvent(event),
        false,
      );
    };
  }, []);

  return <></>;
};
