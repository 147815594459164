import { styled, Typography } from '@mui/material';
import AppButton from 'components/appButton';
import AppLink from 'components/appLink/appLink';
import { COLORS } from 'theme/colors';

type LinkAction = {
  to: string;
  label: string;
};

type ButtonAction = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

interface OverviewHeader {
  boldInfo?: string;
  info?: string;
  actions?: LinkAction | ButtonAction[];
}

const ButtonWrapper = styled('div')(({ theme: { spacing } }) => ({
  '& :not(:last-child)': {
    marginRight: spacing(2),
  },
}));

const HeaderRow = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(5),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2.5),
    },
  }),
);

const LeftInfo = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(3),
    paddingRight: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: spacing(1),
    },
  }),
);

const BoldInfoText = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    color: COLORS.DARK_BLUE.MAIN,
    [breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  }),
);

const renderActions = (actions: OverviewHeader['actions']) => {
  if (actions && 'to' in actions) {
    return <AppLink to={actions.to}>{actions.label}</AppLink>;
  } else {
    return (
      actions &&
      actions.length > 0 &&
      actions.map((action: ButtonAction, index) => (
        <AppButton
          key={index}
          color="secondary"
          variant="text"
          onClick={action?.onClick}
          startIcon={action?.icon}
          disabled={action?.disabled ?? false}
        >
          {action?.label}
        </AppButton>
      ))
    );
  }
};

const OverviewHeader: React.FC<OverviewHeader> = ({
  boldInfo,
  info,
  actions,
}) => {
  renderActions(actions);
  return (
    <HeaderRow>
      <LeftInfo>
        {boldInfo && (
          <BoldInfoText variant="h4">{boldInfo}</BoldInfoText>
        )}
        {info && (
          <Typography
            variant="h4"
            component="div"
            color="secondary"
            fontWeight="normal"
          >
            {info}
          </Typography>
        )}
      </LeftInfo>
      <div>
        <ButtonWrapper>
          {actions && renderActions(actions)}
        </ButtonWrapper>
      </div>
    </HeaderRow>
  );
};

export default OverviewHeader;
