import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem, styled } from '@mui/material';
import { getCompanyData } from 'api';
import AppButton from 'components/appButton';
import ConfirmInvoiceDataDialog from 'components/dialogs/confirmInvoiceDataDialog';
import SelectField from 'components/form/selectField';
import TextField from 'components/form/textFieldWithController';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InputVariant } from 'types/appEnums';
import { Country } from 'types/appTypes';
import { useInvoices } from 'utils/hooks/useInvoices';
import {
  usePostalCode,
  usePostalCodeValidation,
} from 'utils/hooks/usePostalCode';
import { DOWNLOAD_RECEIPT_OR_INVOICE_ERROR } from 'pages/appRoutesConst';
import {
  REGEX_TAX,
  REGEX_CITY,
  REGEX_STREET,
  REGEX_EMAIL,
  REGEX_NAME,
} from 'types/regex';
import * as yup from 'yup';

interface Props {
  payWindowId?: string;
  journeyId?: string;
  countries: Country[];
}

const EMAIL = 'email';
const TAX_ID = 'tax_id';
const COMPANY_NAME = 'name';
const GROUP_TAX_ID = 'group_tax_id';
const COUNTRY = 'country';
const POSTAL_CODE = 'postal_code';
const CITY = 'city';
const STREET = 'street';

// default regex to match everything
const schema = (regex = new RegExp('.*')) => {
  return yup
    .object()
    .shape({
      [EMAIL]: yup
        .string()
        .trim()
        .max(70)
        .required('required')
        .matches(REGEX_EMAIL, 'email_regex_error'),
      [TAX_ID]: yup
        .string()
        .trim()
        .max(20)
        .required('required')
        .matches(REGEX_TAX, 'allowedChar'),
      [COMPANY_NAME]: yup
        .string()
        .trim()
        .max(70)
        .matches(REGEX_NAME, 'allowedChar'),
      [GROUP_TAX_ID]: yup
        .string()
        .trim()
        .max(20)
        .matches(REGEX_TAX, 'allowedChar'),
      [COUNTRY]: yup.string().trim(),
      [POSTAL_CODE]: yup
        .string()
        .trim()
        .required('required')
        .max(10)
        .matches(regex, 'zip_regex_error'),
      [CITY]: yup
        .string()
        .trim()
        .max(35)
        .required('required')
        .matches(REGEX_CITY, 'allowedChar'),
      [STREET]: yup
        .string()
        .trim()
        .max(35)
        .required('required')
        .matches(REGEX_STREET, 'allowedChar'),
    })
    .required();
};

const GridWrapper = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingLeft: spacing(16),
    paddingRight: spacing(16),
    [breakpoints.down('md')]: {
      paddingLeft: spacing(8),
      paddingRight: spacing(8),
    },
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

const ContinueButtonWrapper = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginTop: spacing(5.5),
    textAlign: 'right',
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
    },
  }),
);

const DomesticCompanyTab = ({
  journeyId,
  payWindowId,
  countries,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const [companyDataLoading, setCompanyDataLoading] = useState(false);
  const { postalRegex, handleCountryChange } = usePostalCode({
    countries,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [fieldsShouldBeDisabled, setFieldsShouldBeDisabled] =
    useState(true);
  const { control, handleSubmit, watch, trigger, reset } = useForm({
    resolver: yupResolver(schema(postalRegex)),
    defaultValues: {
      [EMAIL]: '',
      [TAX_ID]: '',
      [COMPANY_NAME]: '',
      [GROUP_TAX_ID]: '',
      [COUNTRY]: '',
      [POSTAL_CODE]: '',
      [CITY]: '',
      [STREET]: '',
    },
    mode: 'onBlur',
  });
  usePostalCodeValidation({
    postalRegex,
    postalCodeName: POSTAL_CODE,
    postalCodeValue: watch(POSTAL_CODE),
    trigger,
  });
  const navigate = useNavigate();
  const { onSubmit } = useInvoices({
    payWindowId,
    journeyId,
    type: 'domestic',
  });

  const handleContinue = async () => {
    const isValid = await trigger();
    if (isValid) {
      setModalOpen(true);
    }
  };

  const handleTaxIdOnBlur = async () => {
    const taxId = watch(TAX_ID);
    const email = watch(EMAIL);
    if (!taxId || !email) return;
    setCompanyDataLoading(true);
    const companyData = await getCompanyData(taxId);
    if (!!taxId && !companyData?.tax_id) {
      navigate(DOWNLOAD_RECEIPT_OR_INVOICE_ERROR);
    }
    if (companyData) {
      const country = companyData?.country || '';
      reset({
        [EMAIL]: email,
        [TAX_ID]: companyData?.tax_id || '',
        [COMPANY_NAME]: companyData?.name.substring(0, 70) || '',
        [GROUP_TAX_ID]:
          companyData?.group_tax_id?.substring(0, 20) || '',
        [COUNTRY]: country,
        [POSTAL_CODE]: companyData?.postal_code || '',
        [CITY]: companyData?.city?.substring(0, 35) || '',
        [STREET]: companyData?.street?.substring(0, 35) || '',
      });
      handleCountryChange(country);
      setFieldsShouldBeDisabled(false);
    }
    setCompanyDataLoading(false);
  };

  return (
    <GridWrapper container rowSpacing={2.5} columnSpacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={EMAIL}
          label={t('components.formFields.email')}
          inputProps={{ maxLength: 70 }}
          required
          customOnBlur={handleTaxIdOnBlur}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={TAX_ID}
          label={t('components.formFields.taxNumber')}
          inputProps={{ maxLength: 20 }}
          required
          customOnBlur={handleTaxIdOnBlur}
          loading={companyDataLoading}
          upperCased
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={COMPANY_NAME}
          inputProps={{ maxLength: 70 }}
          label={t('components.formFields.companyName')}
          required
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={GROUP_TAX_ID}
          label={t('components.formFields.groupTaxNumber')}
          inputProps={{ maxLength: 20 }}
          required
          requiredSpecialText={`${t(
            'components.formFields.requiredFieldIfCompany',
          )}`}
          disabled
          upperCased
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          control={control}
          name={COUNTRY}
          label={t('components.formFields.country')}
          required
          variant="outlined"
          inputVariant={InputVariant.LIGHT}
          customOnChange={handleCountryChange}
          MenuProps={{
            sx: {
              maxHeight: 250,
            },
          }}
          disabled
          renderValue={(selected) =>
            countries.find((value) => value.code === selected)?.[
              resolvedLanguage as keyof Country
            ] ?? <>{selected}</>
          }
        >
          {countries.map((value) => (
            <MenuItem key={value.code} value={value.code}>
              {value[resolvedLanguage as keyof Country] ?? ''}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={POSTAL_CODE}
          label={t('components.formFields.zip')}
          required
          inputProps={{ maxLength: 10 }}
          disabled={fieldsShouldBeDisabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={CITY}
          label={t('components.formFields.city')}
          inputProps={{ maxLength: 35 }}
          required
          disabled={fieldsShouldBeDisabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          name={STREET}
          label={t('components.formFields.address')}
          inputProps={{ maxLength: 35 }}
          required
          disabled={fieldsShouldBeDisabled}
        />
      </Grid>
      <ContinueButtonWrapper
        item
        xs={12}
        md={12}
        alignItems="flex-end"
      >
        <AppButton
          variant="contained"
          color="secondary"
          onClick={handleContinue}
        >
          {t('buttons.continue')}
        </AppButton>
      </ContinueButtonWrapper>
      <ConfirmInvoiceDataDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
    </GridWrapper>
  );
};

export default DomesticCompanyTab;
