import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import pciLogo from 'assets/images/PCI-DSS-white-logo.png';
import { useEffect, useState } from 'react';
import useAuthentication from 'utils/hooks/useAuthentication';
import { removeFromLocalStorage } from 'localStorage';
import { AUTH_DATA } from 'localStorage/localStorageKeys';
import { useSnackbar } from 'notistack';
import PortalBox from 'components/portalBox';
import { portalsType } from 'types/appTypes';
import Loading from 'components/loading';

export const PortalWhiteBox = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    height: '170px',
    width: '170px',
    background: palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(3, 2),
    borderRadius: '20px',
  }),
);

export const PortalTableWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  maxWidth: '800px',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& a': {
    textDecoration: ' none',
  },
  cursor: 'pointer',
}));

const Login: React.FC = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.login',
  });
  const [navigateUrl, setNavigateUrl] = useState<string | null>(null);
  const [isGatewayParam, setIsGatewayParam] = useState<boolean>(true);
  const { loadAndSaveToken } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();

  const loadData = async () => {
    const gatewayQueryParam = new URL(
      window.location.href,
    ).searchParams.get('gateway');

    const gatewayUrl = await loadAndSaveToken({
      gateway: gatewayQueryParam,
    });
    if (gatewayUrl) {
      setNavigateUrl(gatewayUrl);
    } else
      enqueueSnackbar(t('serverErrorSnackbar'), {
        variant: 'error',
      });
  };

  useEffect(() => {
    removeFromLocalStorage({ key: AUTH_DATA });
    loadData();
  }, []);

  useEffect(() => {
    const gatewayParam = new URL(
      window.location.href,
    ).searchParams.get('gateway');

    !gatewayParam && setIsGatewayParam(false);

    if (isGatewayParam && navigateUrl) {
      window.location.href = navigateUrl;
    }
  }, [isGatewayParam, navigateUrl]);

  const portals: portalsType[] = JSON.parse(
    process.env.REACT_APP_API_AUTH_PORTALS ?? '',
  );

  const portalHandler = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    const portal = e.currentTarget.dataset.portal;

    if (portal) {
      await Promise.all(
        portals.map(async (el: portalsType) => {
          if (portal === el.portal) {
            const gatewayUrl = await loadAndSaveToken({
              gateway: el.gateway,
            });
            if (gatewayUrl) {
              window.location.href = gatewayUrl;
            }
          }
        }),
      );
    }
  };

  return (
    <>
      {isGatewayParam && <Loading />}
      {!isGatewayParam && (
        <Box
          sx={{
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: '100px 10px 10px',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}
          >
            <Box>
              <Typography
                color="white"
                variant="h4"
                component="div"
                fontWeight={700}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginBottom: 2,
                }}
              >
                {t('title')}
              </Typography>
              <Typography textAlign="center">
                {t('subtitle')}
              </Typography>
            </Box>
            <PortalTableWrapper>
              {portals?.map((el, index: number) => {
                return (
                  <Box
                    data-portal={el.portal}
                    key={index}
                    onClick={portalHandler}
                  >
                    <PortalWhiteBox>
                      <PortalBox name={el.name} logo={el.logo} />
                    </PortalWhiteBox>
                  </Box>
                );
              })}
            </PortalTableWrapper>
          </Box>
          <div>
            <img src={pciLogo} />
          </div>
        </Box>
      )}
    </>
  );
};

export default Login;
