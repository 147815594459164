import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from 'stores/store';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';

const ProtectedRoute: React.FC = ({ children }) => {
  const location = useLocation();

  const authenticated = useAppSelector(
    (state) => state.user.isAuthenticated,
  );

  if (authenticated) return <>{children}</>;
  // In develoment dont log out on refresh
  if (process.env.NODE_ENV === 'development') return <>{children}</>;

  const gatewayValue = loadFromLocalStorage({ key: GATEWAY });

  return (
    <Navigate
      to={{
        pathname: '/login',
        search: gatewayValue
          ? `gateway=${gatewayValue}`
          : location.search,
      }}
      replace
      state={{ path: location.pathname }}
    />
  );
};

export default ProtectedRoute;
