import { MenuItem, styled } from '@mui/material';
import { PTO, RideWithPTO } from 'types/appTypes';
import {
  formatDateToDay,
  formatDateToTime,
} from 'utils/funcs/formatDate';
import { formatIntegerToLocalString } from 'utils/funcs/formatNumber';
import { useTranslation } from 'react-i18next';

interface Props {
  pto: PTO[];
  journey: RideWithPTO;
  value: string;
}

const Wrapper = styled('div')(() => ({
  width: '100%',
  fontSize: '0.875rem',
  display: 'flex',
  gap: '0 1.25rem',
  flexWrap: 'wrap',
}));

const Date = styled('span')(() => ({
  display: 'inline-block',
}));

const Operator = styled('span')(() => ({
  display: 'inline-block',
}));

const Price = styled('span')(() => ({
  display: 'inline-block',
  fontWeight: 700,
  textAlign: 'right',
  flex: 1,
}));

export const JourneySelectMenuItemValue = ({
  journey: { start, price, price_currency, pto_id, reason },
  pto,
}: Omit<Props, 'value'>) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.help.reason',
  });

  const ptoName = pto.find((value) => value.id === pto_id)?.name;
  return (
    <Wrapper>
      <Date>{`${formatDateToDay(start)} ${formatDateToTime(
        start,
        'HH:mm',
      )}`}</Date>
      <Operator>{ptoName}</Operator>
      <span>{t(reason, reason)}</span>
      <Price>{`${formatIntegerToLocalString(
        price / 100,
      )} ${price_currency}`}</Price>
    </Wrapper>
  );
};

const JourneySelectMenuItem = ({
  journey,
  pto,
  value,
  ...rest
}: Props) => {
  return (
    <MenuItem {...rest} value={value}>
      <JourneySelectMenuItemValue journey={journey} pto={pto} />
    </MenuItem>
  );
};

export default JourneySelectMenuItem;
