import { styled, Typography, useTheme } from '@mui/material';
import AppButton from 'components/appButton';
import AppImage from 'components/appImage';
import { CONTACT_DETAIL_URL } from 'pages/appRoutesConst';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface ContactBoxProps {
  id: string;
  name: string;
  phone: string;
  email: string;
  logo: string;
}

const InfoWrapper = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const LogoWrapper = styled('div')(({ theme: { spacing } }) => ({
  height: '30%',
  marginBottom: spacing(3),
}));
const TextWrapper = styled('div')(({ theme: { spacing } }) => ({
  height: '30%',
  marginBottom: spacing(5),
  textAlign: 'center',
}));

const EmailLink = styled('a')(() => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const ContactBox: React.FC<ContactBoxProps> = ({
  id,
  name,
  email,
  phone,
  logo,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common', {
    keyPrefix: 'components.contactBox',
  });
  const theme = useTheme();
  return (
    <InfoWrapper>
      <LogoWrapper>
        <Typography sx={{ height: '100%' }} component="div">
          <AppImage path={logo} />
        </Typography>
      </LogoWrapper>
      <TextWrapper>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography
          color={theme.palette.text.lightGrey.main}
          component="div"
        >
          {phone}
        </Typography>
        <Typography
          color={theme.palette.text.lightGrey.main}
          sx={{ mb: theme.spacing(6) }}
          component="div"
        >
          {email && (
            <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
          )}
        </Typography>
      </TextWrapper>
      <div>
        <AppButton
          variant="contained"
          color="secondary"
          onClick={() => navigate(`${CONTACT_DETAIL_URL}/${id}`)}
        >
          {t('showMore')}
        </AppButton>
      </div>
    </InfoWrapper>
  );
};

export default ContactBox;
