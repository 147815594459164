import { styled, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as TikTokIcon } from 'assets/icons/tiktok.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/youtube.svg';
import AppLink from 'components/appLink/appLink';
import { PTO, TLocalizedLink } from 'types/appTypes';
import { useParams } from 'react-router';
import { getPto } from 'api';
import AppImage from 'components/appImage';

const Column = styled('div')(() => ({
  flex: '1 1 0',
  display: 'flex',
  flexDirection: 'column',
}));

const Value = styled(Typography)(({ theme: { palette } }) => ({
  fontWeight: 'bold',
  color: palette.text.darkBlue.main,
}));

const Title = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const SocialWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  'a:not(:first-of-type)': {
    marginLeft: spacing(1),
  },
}));

const LogoWrapper = styled(Column)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginRight: spacing(10),
    maxWidth: spacing(30),
    [breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: spacing(3),
      alignSelf: 'center',
    },
  }),
);

const StyledLink = styled('a')(() => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const ContactDetail: React.FC = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', {
    keyPrefix: 'components.contactDetail',
  });

  const theme = useTheme();
  const { id } = useParams();
  const [data, setData] = useState<PTO | null>(null);
  const getLocalizedUrl = (value: TLocalizedLink) => {
    if (resolvedLanguage in value) {
      return value[resolvedLanguage];
    } else {
      return value['default'];
    }
  };

  useEffect(() => {
    if (id) loadData(id);
  }, []);

  const loadData = async (id: string) => {
    const result = await getPto(id);
    if (result) setData(result);
  };

  const iconSx = {
    width: theme.spacing(4),
    height: theme.spacing(4),
  };

  // const rootUrl = process.env.REACT_APP_API_KEY;

  return (
    <>
      {!!data && (
        <>
          <div>
            <Typography variant="h4">{data.name}</Typography>
          </div>
          <Box
            sx={{
              mt: 4,
              width: '100%',
              px: 4,
              py: 8,
              minHeight: theme.spacing(45),
              display: 'flex',
              flexDirection: ['column', 'row'],
              background: theme.palette.background.paper,
              borderRadius: 2,
            }}
          >
            <LogoWrapper>
              <AppImage path={data.logo} />
            </LogoWrapper>
            <Column sx={{ ' > *': { marginBottom: 3 } }}>
              <div>
                <Title>{t('phone')}</Title>
                <Value>{data.phone}</Value>
              </div>
              <div>
                <Title>{t('email')}</Title>
                <Value>
                  {data.email && (
                    <StyledLink href={`mailto:${data.email}`}>
                      {data.email}
                    </StyledLink>
                  )}
                </Value>
              </div>
              <div>
                <Value>
                  {data.web && (
                    <StyledLink href={data.web} target="_blank">
                      {data.web}
                    </StyledLink>
                  )}
                </Value>
              </div>
              <div>
                <Value>
                  {data.web && (
                    <StyledLink
                      href={getLocalizedUrl(
                        data.dataprotection_document,
                      )}
                      // href={`${rootUrl}/documents/${data.dataprotection_document}`}
                      target="_blank"
                    >
                      {t('dataProtection')}
                    </StyledLink>
                  )}
                </Value>
              </div>
              <div>
                <Value>
                  {data.web && (
                    <StyledLink
                      href={getLocalizedUrl(
                        data.terms_and_conditions_document,
                      )}
                      // href={`${rootUrl}/documents/${data.terms_and_conditions_document}`}
                      target="_blank"
                    >
                      {t('termsConditions')}
                    </StyledLink>
                  )}
                </Value>
              </div>
              <SocialWrapper>
                {data.facebook && (
                  <AppLink isExternal to={data.facebook}>
                    <FacebookIcon
                      sx={{
                        ...iconSx,
                      }}
                      color="secondary"
                    />
                  </AppLink>
                )}
                {data.linkedin && (
                  <AppLink isExternal to={data.linkedin}>
                    <LinkedInIcon
                      sx={{
                        ...iconSx,
                      }}
                      color="secondary"
                    />
                  </AppLink>
                )}
                {data.twitter && (
                  <AppLink isExternal to={data.twitter}>
                    <TwitterIcon
                      sx={{
                        ...iconSx,
                      }}
                      color="secondary"
                    />
                  </AppLink>
                )}
                {data.instagram && (
                  <AppLink isExternal to={data.instagram}>
                    <InstagramIcon
                      sx={{
                        ...iconSx,
                      }}
                      color="secondary"
                    />
                  </AppLink>
                )}
                {data.tiktok && (
                  <AppLink isExternal to={data.tiktok}>
                    <TikTokIcon
                      style={{ width: '24px', height: '24px' }}
                    />
                  </AppLink>
                )}
                {data.youtube && (
                  <AppLink isExternal to={data.youtube}>
                    <YouTubeIcon
                      style={{ width: '24px', height: '24px' }}
                    />
                  </AppLink>
                )}
              </SocialWrapper>
            </Column>
            <Column>
              <div>
                <div>
                  <Title>{t('basicInfo')}</Title>
                  <div>
                    <Value
                      dangerouslySetInnerHTML={{
                        __html: data.basicInfo,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Column>
          </Box>
        </>
      )}
    </>
  );
};

export default ContactDetail;
