import {
  Select,
  MenuItem,
  useTheme,
  alpha,
  darken,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface UseLanguageSelectProps {
  color?: 'whiteText';
  isMobile?: boolean;
}

const LANGUAGES = [
  { value: 'en', label: 'english' },
  { value: 'cs', label: 'czech' },
  { value: 'sk', label: 'slovak' },
  { value: 'de', label: 'german' },
  { value: 'hu', label: 'hungarian' },
];

const useLanguageSelect = ({
  color = undefined,
  isMobile,
}: UseLanguageSelectProps = {}) => {
  const {
    t,
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation('common', {
    keyPrefix: 'navigation.languages',
  });

  const theme = useTheme();
  const { palette } = theme;

  const variableColor = useMemo(
    () =>
      color === 'whiteText'
        ? palette.text.white.main
        : palette.text.black.main,
    [color],
  );

  return (
    <Select
      value={resolvedLanguage}
      onChange={(e) => changeLanguage(e.target.value)}
      MenuProps={{
        sx: {
          '&& .MuiPopover-paper': {
            backgroundColor:
              color === 'whiteText'
                ? alpha(palette.common.black, 0.3)
                : palette.text.white.main,
            '& .Mui-selected': {
              backgroundColor:
                color === 'whiteText'
                  ? darken(palette.common.black, 0.3)
                  : 'initial',
            },
            ' .MuiMenuItem-root:hover': {
              backgroundColor:
                color === 'whiteText'
                  ? alpha(palette.text.white.main, 0.3)
                  : palette.primary.main,
            },
            ' .MuiMenuItem-root': {
              backgroundColor:
                color === 'whiteText' ? 'transparent' : 'initial',
            },
          },
        },
      }}
      sx={{
        color: variableColor,
        '&.MuiOutlinedInput-root .MuiSelect-icon': {
          color: variableColor,
        },
        '&.MuiOutlinedInput-root': {
          width: isMobile ? '100%' : 'initial',
        },
        '& .MuiOutlinedInput-input': {
          padding: 0,
        },
        '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      }}
      IconComponent={ArrowDropDownIcon}
    >
      {LANGUAGES.map((lang, index) => (
        <MenuItem
          sx={{ color: variableColor }}
          key={index}
          value={lang.value}
        >
          {t(lang.label)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default useLanguageSelect;
