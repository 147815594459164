import { Button, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ManCircleIcon } from 'assets/icons/man_circle.svg';
import { ReactComponent as ModerBuildingsIcon } from 'assets/icons/modern_buildings.svg';
import { ReactComponent as RealEstateIcon } from 'assets/icons/real_estate.svg';
import { useEffect, useState } from 'react';
import PrivatePersonTab from 'components/organisms/downloadReceiptOrInvoice/PrivatePersonTab';
import DomesticCompanyTab from 'components/organisms/downloadReceiptOrInvoice/DomesticCompanyTab';
import ForeignCompanyTab from 'components/organisms/downloadReceiptOrInvoice/ForeignCompanyTab';
import { useParams } from 'react-router';
import { Country } from 'types/appTypes';
import { getCountries } from 'api';
import { sortCountriesByLanguage } from 'utils/funcs/transform';

const Wrapper = styled('div')(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    marginTop: spacing(5),
    padding: spacing(7.5, 9, 9, 9),
    background: palette.common.white,
    borderRadius: 8,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  }),
);

const TabButtonsWrapper = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'grid',
    gridGap: spacing(3),
    gridTemplateColumns: `repeat(3,1fr)`,
    marginTop: spacing(5),
    marginBottom: spacing(10),
    [breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(1,1fr)`,
      marginTop: spacing(3),
      gridGap: spacing(1),
      marginBottom: spacing(5),
    },
  }),
);

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(
  ({ active, theme: { palette, spacing, breakpoints } }) => ({
    height: '88px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: active ? palette.primary.dark : '#EFEFEF',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: spacing(0, 4, 0, 4),
    borderRadius: '12px',
    color: palette.text.darkBlue.main,
    background: active ? palette.primary.light : palette.common.white,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1rem',
    '&:hover': {
      borderColor: palette.primary.dark,
      background: active
        ? palette.primary.light
        : palette.common.white,
    },
    [breakpoints.down('sm')]: {
      height: '70px',
      padding: spacing(0, 3, 0, 3),
    },
  }),
);

const IconWrapper = styled('span')(({ theme: { spacing } }) => ({
  marginRight: spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const DownloadReceiptOrInvoicePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.downloadReceiptOrInvoice',
  });
  const params = useParams();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = async () => {
    const result = await getCountries();
    if (result) {
      setCountries(result);
    }
  };

  const renderActiveTab = () => {
    if (activeTab === 0) {
      return (
        <PrivatePersonTab
          {...params}
          countries={sortCountriesByLanguage(countries)}
        />
      );
    }
    if (activeTab === 1) {
      return (
        <DomesticCompanyTab
          {...params}
          countries={sortCountriesByLanguage(countries)}
        />
      );
    }
    if (activeTab === 2) {
      return (
        <ForeignCompanyTab
          {...params}
          countries={sortCountriesByLanguage(countries)}
        />
      );
    }
  };

  const tabButtons = [
    {
      title: t('privatePerson'),
      Icon: ManCircleIcon,
    },
    {
      title: t('domesticCompany'),
      Icon: RealEstateIcon,
    },
    {
      title: t('foreignCompany'),
      Icon: ModerBuildingsIcon,
    },
  ];

  return (
    <>
      <Typography variant="h4">{t(`pageTitle`)}</Typography>
      <Wrapper>
        <Typography variant="h5" fontWeight={700}>
          {t(`boxTitle`)}
        </Typography>
        <TabButtonsWrapper>
          {tabButtons.map(({ title, Icon }, index) => (
            <TabButton
              key={title}
              onClick={() => setActiveTab(index)}
              active={index === activeTab}
              disableRipple
            >
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </TabButton>
          ))}
        </TabButtonsWrapper>
        {renderActiveTab()}
      </Wrapper>
    </>
  );
};

export default DownloadReceiptOrInvoicePage;
