import AppSelect, {
  Props as AppSelectProps,
} from 'components/form/select';
import { Control, Controller } from 'react-hook-form';
import { InputVariant } from 'types/appEnums';

type Props = {
  control: Control<any, object>; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  errorText?: string;
  fontWeight?: number;
  inputVariant?: InputVariant;
  customOnChange?: (value: string) => void;
} & AppSelectProps;

const SelectField = ({
  control,
  name,
  inputVariant = InputVariant.BOLD,
  customOnChange,
  ...rest
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <AppSelect
          inputVariant={inputVariant}
          onChange={(event) => {
            customOnChange &&
              customOnChange(event.target.value as string);
            onChange(event);
          }}
          onBlur={onBlur}
          value={value}
          name={name}
          error={!!error?.message}
          errorText={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default SelectField;
