import { COLORS } from './colors';

export const typographyThemeOptions = {
  fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: '5.5rem',
    fontWeight: 700,
    color: COLORS.DARK_BLUE.MAIN,
  },
  h2: {
    fontSize: '3.56rem',
    fontWeight: 700,
    color: COLORS.DARK_BLUE.MAIN,
  },
  h3: {
    fontSize: '3rem',
    fontWeight: 700,
    color: COLORS.DARK_BLUE.MAIN,
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 700,
    color: COLORS.DARK_BLUE.MAIN,
  },
  h5: {
    fontSize: '1.43rem',
    fontWeight: 600,
    color: COLORS.DARK_BLUE.MAIN,
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: COLORS.DARK_BLUE.MAIN,
  },
  subtitle1: {
    fontSize: '0.93rem',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '0.91rem',
    fontWeight: 400,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  body2: {
    fontSize: '0.87rem',
    fontWeight: 400,
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  caption: {
    fontSize: '0.87rem',
    fontWeight: 400,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
};
