import { useTheme } from '@mui/material';
import { getDownloadToken } from 'api';
import { Link, LinkProps } from 'react-router-dom';

const CUSTOM_ANCHOR_ATTRIBUTE_NAME = 'data-is-href-updated';

const webViewDownloadLinkOnClick = async (
  id: string,
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => {
  const target = e.target as HTMLAnchorElement;

  if (!target?.dataset.isHrefUpdated) {
    e.preventDefault();
    const randomUuid = await getDownloadToken();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (randomUuid && !target?.href.includes(randomUuid)) {
      target?.setAttribute(
        'href',
        target.href.concat(`?token=${randomUuid}`),
      );
      target?.setAttribute(CUSTOM_ANCHOR_ATTRIBUTE_NAME, 'true');
      target.click();
    }
  }
};

// Component to standardize link. Can be used to link to external routes. Have special implementation for webview.
const AppLink: React.FC<
  LinkProps & {
    id?: string;
    isExternal?: boolean;
    isWebviewDownloadLink?: boolean;
  }
> = ({
  children,
  isExternal,
  isWebviewDownloadLink,
  id,
  ...rest
}) => {
  const theme = useTheme();

  if (isExternal && !!rest.to) {
    return (
      <a
        {...rest}
        onClick={(e) => {
          if (isWebviewDownloadLink && id)
            webViewDownloadLinkOnClick(id, e);
        }}
        href={rest.to as string}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.text.darkBlue.main }}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      style={{ color: theme.palette.text.darkBlue.main }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default AppLink;
