import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
} from '@mui/material';
import AppButton from 'components/appButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/lab';
import AppTextField from 'components/form/textField';
import AppSelect from 'components/form/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  formatDateForBackend,
  getLocalizedPlaceholder,
} from 'utils/funcs/formatDate';
import { getReceiptsForMultiplePayWindows } from 'api';

interface RidesDialogProps {
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  isOpen: boolean;
  onClose: () => void;
}

interface DialogState {
  dateFrom: Dayjs | null;
  dateFromError: boolean;
  dateFromErrorText: string;
  dateTo: Dayjs | null;
  dateToError: boolean;
  dateToErrorText: string;
  type: 'csv' | 'pdf';
}

export const ExportRidesDialog: React.FC<RidesDialogProps> = ({
  startDate = null,
  endDate = null,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'components.exportRidesDialog',
  });

  const [formState, setFormState] = useState<DialogState>({
    dateFrom: startDate,
    dateFromError: false,
    dateFromErrorText: '',
    dateTo: endDate,
    dateToError: false,
    dateToErrorText: '',
    type: 'csv',
  });

  useEffect(() => {
    setFormState({ ...formState, dateFrom: startDate });
  }, [startDate]);

  useEffect(() => {
    setFormState({ ...formState, dateTo: endDate });
  }, [endDate]);

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>
        {t('dialogTitle')}
        {onClose && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DatePicker
          label={t('dateFrom')}
          value={formState.dateFrom}
          onChange={(newValue) => {
            if (newValue)
              setFormState({
                ...formState,
                dateFrom: newValue,
                dateFromError: false,
                dateFromErrorText: 'required',
                dateToError: false,
                dateToErrorText: 'required',
              });
          }}
          renderInput={(params) => (
            <AppTextField
              sx={{ marginBottom: 2, marginTop: -1 }}
              fullWidth
              required
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: getLocalizedPlaceholder(),
              }}
              error={formState.dateFromError}
              errorText={formState.dateFromErrorText}
            />
          )}
        />
        <DatePicker
          label={t('dateTo')}
          value={formState.dateTo}
          onChange={(newValue) => {
            if (newValue)
              setFormState({
                ...formState,
                dateTo: newValue,
                dateFromError: false,
                dateFromErrorText: 'required',
                dateToError: false,
                dateToErrorText: 'required',
              });
          }}
          renderInput={(params) => (
            <AppTextField
              sx={{ marginBottom: 2, marginTop: -1 }}
              fullWidth
              required
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: getLocalizedPlaceholder(),
              }}
              error={formState.dateToError}
              errorText={formState.dateToErrorText}
            />
          )}
        />
        <AppSelect
          sx={{ marginTop: -1 }}
          fullWidth
          required
          displayEmpty
          label={t('fileType')}
          value={t(`${formState.type}`)}
          onChange={(e) => {
            if (e.target.value === 'pdf' || e.target.value === 'csv')
              setFormState({
                ...formState,
                type: e.target.value,
              });
          }}
        >
          <MenuItem key={'csv'} value={'csv'}>
            {t('csv')}
          </MenuItem>
          <MenuItem key={'pdf'} value={'pdf'}>
            {t('pdf')}
          </MenuItem>
        </AppSelect>
        <Box sx={{ marginTop: 3 }}>
          <AppButton
            variant="contained"
            color="secondary"
            disabled={
              (formState.dateFrom &&
                !dayjs(formState.dateFrom).isValid()) ||
              (formState.dateTo &&
                !dayjs(formState.dateTo).isValid()) ||
              false
            }
            onClick={async () => {
              if (!formState.dateFrom || !formState.dateTo) {
                setFormState({
                  ...formState,
                  dateFromError: !formState.dateFrom,
                  dateFromErrorText: 'required',
                  dateToError: !formState.dateTo,
                  dateToErrorText: 'required',
                });
              } else if (
                dayjs(formState.dateTo).isSameOrBefore(
                  dayjs(formState.dateFrom).add(3, 'M'),
                  'd',
                ) &&
                dayjs(formState.dateTo).isSameOrAfter(
                  dayjs(formState.dateFrom),
                  'd',
                )
              ) {
                await getReceiptsForMultiplePayWindows(
                  formState.type,
                  formState.type,
                  {
                    from: formState.dateFrom
                      ? `${formatDateForBackend(formState.dateFrom)}`
                      : '',
                    to: formState.dateTo
                      ? `${formatDateForBackend(formState.dateTo)}`
                      : '',
                  },
                );
              } else {
                setFormState({
                  ...formState,
                  dateFromError: true,
                  dateFromErrorText: 'date_range_3_months',
                  dateToError: true,
                  dateToErrorText: 'date_range_3_months',
                });
              }
            }}
          >
            {t('download')}
          </AppButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
