import dayjs, { Dayjs } from 'dayjs';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';

export const formatDateToDay = (
  date: Date | Dayjs,
  format?: string,
) => {
  if (dayjs(date).isValid()) {
    if (!format) {
      const gateway = loadFromLocalStorage({ key: GATEWAY });
      const getLocale = (language: string) => {
        switch (language) {
          case 'en':
            return 'MM/DD/YYYY';
          case 'cz':
            return 'D.MM.YYYY';
          case 'rgn':
            return 'D.MM.YYYY';
          case 'hu':
            return 'YYYY.MM.D';
          default:
            return 'D.MM.YYYY';
        }
      };

      format = getLocale(gateway);
    } else {
      format = 'D.MM.YYYY';
    }
    return dayjs(date).format(format);
  } else {
    return undefined;
  }
};

export const formatDateToTime = (
  date: Date | Dayjs,
  format = 'hh:mm',
) => dayjs(date).format(format);

export const getLocalizedPlaceholder = () => {
  const gateway = loadFromLocalStorage({ key: GATEWAY });

  const getLocale = (language: string) => {
    switch (language) {
      case 'en':
        return 'Month.day.year';
      case 'cz':
        return 'Den.měsíc.rok';
      case 'rgn':
        return 'Jahr.monat.tag';
      case 'hu':
        return 'Év.hónap.nap';
      default:
        return 'Month.day.year';
    }
  };

  return getLocale(gateway);
};

export const formatDateForBackend = (
  date: Date | Dayjs,
  format = 'DD.MM.YYYY',
) => dayjs(date).format(format);
