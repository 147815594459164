import { alpha } from '@mui/material';

export const COLORS = {
  LIGHT_BLUE: {
    LIGHT: '#DDF2F6',
    MAIN: '#C1E4EB',
    DARK: '#8DC9D6',
  },
  DARK_BLUE: {
    MAIN: '#001145',
  },
  LIGHT_GREEN: {
    LIGHT: alpha('#00B929', 0.05),
    MAIN: '#00B929',
  },
  ORANGE: {
    MAIN: '#f79a1b',
  },
  RED: {
    LIGHT: alpha('#F54432', 0.05),
    MAIN: '#F54432',
  },
  LIGHT_GREY: {
    LIGHT: '#FAFAFA',
    MAIN: '#F5F5F5',
    DARK: '#E0E0E0',
  },
  GREY: {
    MAIN: '#666673',
    DARK: '#001145',
    LIGHT: '#919EC8',
  },
  BLACK: '#000000',
  WHITE: '#FFFFFF',
};
