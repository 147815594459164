import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';

export const numberToLocalString = (number: number, local = 'cs') =>
  number.toLocaleString(local);

export const integerToFloatNumber = (number: number) =>
  parseFloat(number.toFixed(2));

export const integerToFloatText = (number: number) =>
  number.toFixed(2);

export const formatIntegerToLocalString = (number: number) => {
  const local = loadFromLocalStorage({ key: GATEWAY });
  return number
    .toFixed(local === 'hu' ? 0 : 2)
    .toString()
    .replace('.', ',');
};
