import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  name: string;
  lastName: string;
  id: number;
  isAuthenticated: boolean;
}

const initialState: User = {
  name: '',
  lastName: '',
  id: 1,
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUser: (state, action: PayloadAction<Partial<User>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    changeAuthenticatedState: (
      state,
      action: PayloadAction<User['isAuthenticated']>,
    ) => {
      state = { ...state, isAuthenticated: action.payload };
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeUser, changeAuthenticatedState } =
  userSlice.actions;

export default userSlice.reducer;
