import {
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AppButton from 'components/appButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AppButtonStyled = styled(AppButton)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.down('md')]: {
      width: '100%',
    },
  }),
);

const ConfirmInvoiceDataDialog = ({
  onConfirm,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'components.confirmInvoiceDialog',
  });

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        {t('desc')}
        {onClose && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2.5} columnSpacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <AppButtonStyled
              variant="contained"
              color="secondary"
              onClick={onConfirm}
            >
              {t('confirm')}
            </AppButtonStyled>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppButtonStyled
              variant="outlined"
              color="secondary"
              onClick={onClose}
            >
              {t('close')}
            </AppButtonStyled>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmInvoiceDataDialog;
