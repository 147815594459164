import { api, notAuthenticatedApi } from '.';
import { errorHandling } from 'utils/funcs/errorHandling';
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from 'localStorage';
import { AUTH_DATA, GATEWAY } from 'localStorage/localStorageKeys';
export interface TokenResponse {
  token: string;
  refresh_token: string;
}

interface LoginResponse extends TokenResponse {
  gtw_url: string;
}

interface LoginCheckResponse {
  logged: boolean;
  message: string;
}
interface LogoutResponse {
  message: string;
}

export interface getLoginParams {
  gateway?: string | null;
  env?: string;
}
const login = async (params: getLoginParams) => {
  const gatewayQueryParam = new URL(
    window.location.href,
  ).searchParams.get('gateway');

  const gateway =
    gatewayQueryParam || loadFromLocalStorage({ key: GATEWAY });

  if (gateway) {
    saveToLocalStorage({ key: GATEWAY, value: gateway });
  }

  try {
    const { data } = await notAuthenticatedApi.get<LoginResponse>(
      '/login',
      { params },
    );
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const refreshJwtToken = async (
  refreshToken: TokenResponse['refresh_token'],
) => {
  try {
    const { data } = await notAuthenticatedApi.get<TokenResponse>(
      '/token/refresh',
      {
        params: { refresh_token: refreshToken },
      },
    );
    saveToLocalStorage({
      key: AUTH_DATA,
      value: JSON.stringify({
        ...data,
      }),
    });
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const loginCheck = async () => {
  const loginData: TokenResponse = {
    ...JSON.parse(
      loadFromLocalStorage({
        key: AUTH_DATA,
      }),
    ),
  };
  try {
    const { data } =
      await notAuthenticatedApi.get<LoginCheckResponse>(
        '/logincheck',
        {
          headers: { Authorization: 'Bearer ' + loginData.token },
        },
      );
    return data.logged;
  } catch (e) {
    errorHandling(e);
  }
};

const logout = async () => {
  try {
    const { data } = await api.post<LogoutResponse>('/logout');
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

export { login, refreshJwtToken, loginCheck, logout };
