import { EventHandler } from 'components/eventHandler';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/lab';
// import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AppRoutes from './appRoutes';
import cs from 'date-fns/locale/cs';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import hu from 'date-fns/locale/hu';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';
import { useEffect } from 'react';

export default function AppLocalizer() {
  // const { i18n } = useTranslation();
  const gateway = loadFromLocalStorage({ key: GATEWAY });

  const getLocaleSettings = (language: string) => {
    switch (language) {
      case 'en':
        return en;
      case 'cz':
        return cs;
      case 'rgn':
        return de;
      case 'hu':
        return hu;
      default:
        return en;
    }
  };

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (gateway === 'hu') {
      document.title = 'Ügyfélportál BP Pay&GO';
    } else {
      document.title = 'Customer portal';
    }
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={getLocaleSettings(gateway)}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          height: 100,
          width: 475,
          flexGrow: 0,
          fontSize: '24px',
        }}
      >
        <EventHandler />
        <AppRoutes />
      </SnackbarProvider>
    </LocalizationProvider>
  );
}
