import {
  getLoginParams,
  login,
  loginCheck,
  logout,
  refreshJwtToken,
} from 'api/authenticationApi';
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from 'localStorage';
import { AUTH_DATA, GATEWAY } from 'localStorage/localStorageKeys';
import { decodeToken } from 'react-jwt';
import { changeAuthenticatedState } from 'stores/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'stores/store';

export const SECONDS = parseInt(
  process.env.REACT_APP_TOKEN_EXPIRY_IN_SECONDS || '60',
);

const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.user.isAuthenticated,
  );

  const loadAndSaveToken = async (params: getLoginParams) => {
    const response = await login(params);
    if (response) {
      const { refresh_token, token } = response;
      saveToLocalStorage({
        key: AUTH_DATA,
        value: JSON.stringify({
          token,
          refresh_token,
        }),
      });
      return response.gtw_url;
    }
  };

  interface Token {
    exp: number;
    iat: number;
    roles: string[];
    username: string;
  }

  // Check if token is expired.
  const checkTokenAndRefresh = async () => {
    const loginData = loadFromLocalStorage({
      key: AUTH_DATA,
    });

    if (loginData && isAuthenticated) {
      const parsedLoginData = {
        ...JSON.parse(loginData),
      };

      const { refresh_token, token } = parsedLoginData;
      const decodedToken = decodeToken(token) as Token;
      const tokenExpirationDate = new Date(decodedToken.exp * 1000);
      const nowDate = new Date();
      //Expire token 1 minute before real expiration
      nowDate.setSeconds(nowDate.getSeconds() + 60);
      // If token will soon expire request new token
      if (tokenExpirationDate <= nowDate && refresh_token) {
        refreshJwtToken(refresh_token);
      }
    }
  };

  const checkIfLogged = async () => {
    const authData = loadFromLocalStorage({
      key: AUTH_DATA,
    });

    if (authData) {
      const isLogged = await loginCheck();
      return isLogged;
    }
    return false;
  };

  const logOut = async () => {
    await logout();
    dispatch(changeAuthenticatedState(false));
    removeFromLocalStorage({ key: AUTH_DATA });
    removeFromLocalStorage({ key: GATEWAY });
  };

  return {
    logOut,
    checkTokenAndRefresh,
    loadAndSaveToken,
    checkIfLogged,
  };
};

export default useAuthentication;
