import { AppBar, Container } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router';
import { styled } from '@mui/system';
import logo from 'assets/images/switchio_blue.svg';
import backgroundImage from 'assets/images/login-background.png';
import useLanguageSelect from 'utils/hooks/useLanguageSelect';
import { alpha } from '@mui/material/styles';
import AppLink from 'components/appLink/appLink';

const NavBar = styled(AppBar)(({ theme: { spacing, palette } }) => ({
  padding: spacing(2, 0),
  backgroundColor: alpha(palette.common.black, 0.3),
  position: 'sticky',
  top: 0,
  width: '100vw',
}));

const AppWrapper = styled('div')(() => ({
  position: 'relative',
  minHeight: '100vh',
  height: '100vh',
  background: `url(${backgroundImage}) no-repeat center center fixed`,
  backgroundSize: 'cover',
  backgroundColor: 'grey',
  display: 'flex',
  flexDirection: 'column',
}));

const NotAuthenticatedLayout: React.FC = () => {
  const select = useLanguageSelect({ color: 'whiteText' });
  const [gatewayParam] = useState<string | null>(
    new URL(window.location.href).searchParams.get('gateway'),
  );

  return (
    <>
      {gatewayParam && <Outlet />}
      {!gatewayParam && (
        <AppWrapper>
          <NavBar>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <AppLink to="">
                  <img src={logo} />
                </AppLink>
              </div>
              <div>{select}</div>
            </Container>
          </NavBar>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <Outlet />
          </Container>
        </AppWrapper>
      )}
    </>
  );
};

export default NotAuthenticatedLayout;
