import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  fullScreen?: boolean;
  size?: number;
  marginTop?: boolean;
}

const Spinner: FC<Props> = ({
  fullScreen,
  size = 100,
  marginTop = false,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={fullScreen ? '100vh' : '100%'}
      style={{ marginTop: marginTop ? '50px' : '' }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Spinner;
