import AppButton from 'components/appButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, styled } from '@mui/material';
import { IssueTypes } from 'types/consts';
import TextField from 'components/form/textFieldWithController';
import {
  Device,
  PayWindow,
  PTO,
  RideWithPTO,
  TLocalizedLink,
} from 'types/appTypes';
import { reportProblem } from 'api'; //APP_URL,
import { useSnackbar } from 'notistack';
import { InputVariant } from 'types/appEnums';
import SelectField from 'components/form/selectField';
import JourneySelectMenuItem, {
  JourneySelectMenuItemValue,
} from 'components/form/journeySelectMenuItem';
import CheckboxField from 'components/form/checkboxField';
import FileInputField from 'components/form/fileInputField';
import { useEffect, useState } from 'react';

interface Props {
  pto: PTO[];
  devices: Device[];
  journeys: RideWithPTO[];
  currentPto?: PayWindow['pto'];
  currentCategory?: string;
}

const EMAIL = 'email';
const PHONE = 'phone';
const DESCRIPTION = 'description';
const OPERATOR = 'operator';
const DEVICE = 'device';
const CATEGORY = 'category';
const JOURNEY = 'journey';
export const DATA_PROTECTION = 'dataProtection';
export const ATTACHMENT = 'files';

const AttachmentGrid = styled(Grid)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(2),
}));

// const CheckboxGrid = styled(Grid)(() => ({
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   alignItems: 'flex-start',
// }));

const AppButtonStyled = styled(AppButton)(
  ({ theme: { breakpoints } }) => ({
    minWidth: '171px',
    [breakpoints.down('md')]: {
      minWidth: '100%',
    },
  }),
);

// const CheckboxLabelLink = styled(Grid)(() => ({
//   fontWeight: 'bold',
//   textDecoration: 'underline',
//   cursor: 'pointer',
//   marginLeft: '-10px',
// }));

// const StyledLink = styled('a')(() => ({
//   color: 'inherit',
//   textDecoration: 'none',
//   marginLeft: '-10px',
//   fontWeight: 'bold',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));

export type FormValues = {
  [EMAIL]: string;
  [PHONE]: string;
  [DESCRIPTION]: string;
  [OPERATOR]: string;
  [DEVICE]: string;
  [CATEGORY]: string;
  [JOURNEY]: string;
  [DATA_PROTECTION]: boolean;
  [ATTACHMENT]: File;
};

const schema = yup
  .object()
  .shape({
    [EMAIL]: yup.string().required('required').email('validEmail'),
    [PHONE]: yup.string(),
    [DESCRIPTION]: yup.string().required('required'),
    [OPERATOR]: yup.string().required('required'),
    [DEVICE]: yup.string(),
    [CATEGORY]: yup.string(),
    [JOURNEY]: yup.string(),
    [DATA_PROTECTION]: yup.boolean().oneOf([true], 'required'),
    [ATTACHMENT]: yup.mixed(),
  })
  .required();

const checkIfRequired = (fieldName: string) =>
  schema.fields[fieldName]?.exclusiveTests?.required;

const HelpForm = ({
  pto,
  currentPto,
  devices,
  journeys,
  currentCategory,
}: // fetchMoreData,
Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', {
    keyPrefix: 'pages.help',
  });

  const CheckboxGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection:
      resolvedLanguage === 'cs' || resolvedLanguage === 'de'
        ? 'row'
        : 'column',
    // marginLeft: resolvedLanguage === 'cs' ? '-10px' : '0px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }));

  const getLocalizedUrl = (value: TLocalizedLink) => {
    if (resolvedLanguage in value) {
      return value[resolvedLanguage];
    } else {
      return value['default'];
    }
  };

  const StyledLink = styled('a')(() => ({
    color: 'inherit',
    textDecoration: 'none',
    marginLeft:
      resolvedLanguage === 'cs' || resolvedLanguage === 'de'
        ? '-10px'
        : '30px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const { t: commonT } = useTranslation('common');

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [EMAIL]: '',
      [PHONE]: '',
      [DESCRIPTION]: '',
      [OPERATOR]: currentPto?.id ?? '',
      [DEVICE]: '',
      [CATEGORY]: currentCategory ?? '',
      [JOURNEY]: '',
      [DATA_PROTECTION]: false,
      [ATTACHMENT]: null,
    },
  });

  const onSubmit = async (data: FormValues) => {
    const response = await reportProblem(
      data,
      `language=${resolvedLanguage}`,
    );
    if (response?.status === 200) {
      enqueueSnackbar(t('snackbar.ticketSend'), {
        variant: 'success',
      });
      reset();
    }
  };

  const gridItemProps = {
    xs: 12,
    md: 6,
    item: true,
  };

  const [documentRedirLink, setDocumentRedirLink] = useState('');
  const dataProtection = pto.find(
    (row) => row.dataprotection_document,
  );

  useEffect(() => {
    const operatorId = watch(OPERATOR);
    const ptoObject = pto.find((row) => row.id === operatorId);

    if (ptoObject) {
      setDocumentRedirLink(
        getLocalizedUrl(ptoObject.dataprotection_document),
      );
    } else {
      if (dataProtection) {
        setDocumentRedirLink(
          getLocalizedUrl(dataProtection.dataprotection_document),
        );
      } else {
        if (resolvedLanguage === 'hu') {
          setDocumentRedirLink(
            'https://bkk.hu/magunkrol/jogi-nyilatkozat/',
          );
        } else {
          setDocumentRedirLink(
            'https://bkk.hu/en/about-bkk/legal-disclaimer/',
          );
        }
      }
    }
  }, [watch(OPERATOR), resolvedLanguage, dataProtection]);

  const sortedJourneys = journeys.sort(
    (a, b) =>
      new Date(b.start).getTime() - new Date(a.start).getTime(),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={6} rowSpacing={3}>
        <Grid {...gridItemProps}>
          <SelectField
            control={control}
            name={CATEGORY}
            label={t(CATEGORY)}
            required={checkIfRequired(CATEGORY)}
            variant="outlined"
            placeholder={t('categoryPlaceholder')}
          >
            {IssueTypes.map((issueType, index) => (
              <MenuItem key={index} value={commonT(issueType.label)}>
                {commonT(issueType.label)}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid {...gridItemProps}>
          <SelectField
            MenuProps={{
              style: { maxHeight: 200 },
            }}
            control={control}
            name={JOURNEY}
            label={t(JOURNEY)}
            required={checkIfRequired(JOURNEY)}
            variant="outlined"
            placeholder={t('journeyPlaceholder')}
            renderValue={(journeyId) => (
              <JourneySelectMenuItemValue
                journey={
                  sortedJourneys.find(
                    (journey) => journey.id === journeyId,
                  ) as RideWithPTO
                }
                pto={pto}
              />
            )}
          >
            {sortedJourneys.map((journey) => (
              <JourneySelectMenuItem
                key={journey.id}
                value={journey.id}
                pto={pto}
                journey={journey}
              />
            ))}
          </SelectField>
        </Grid>
        <Grid {...gridItemProps}>
          <TextField
            control={control}
            name={EMAIL}
            label={t(EMAIL)}
            required={checkIfRequired(EMAIL)}
            inputVariant={InputVariant.BOLD}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <TextField
            control={control}
            name={PHONE}
            label={t(PHONE)}
            required={checkIfRequired(PHONE)}
            inputVariant={InputVariant.BOLD}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <SelectField
            control={control}
            name={OPERATOR}
            label={t(OPERATOR)}
            required={checkIfRequired(OPERATOR)}
            variant="outlined"
            placeholder={t('operatorPlaceholder')}
            renderValue={(selected) =>
              pto.find((value) => value.id === selected)?.name ?? (
                <>{selected}</>
              )
            }
          >
            {pto.map((value, index) => (
              <MenuItem key={index} value={value.id}>
                {value.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid {...gridItemProps}>
          <SelectField
            control={control}
            name={DEVICE}
            label={t(DEVICE)}
            required={checkIfRequired(DEVICE)}
            variant="outlined"
            placeholder={t('devicePlaceholder')}
            renderValue={(selected) =>
              devices.find((device) => device.id === selected)
                ?.name ?? <>{selected}</>
            }
          >
            {devices.map((device, index) => (
              <MenuItem key={index} value={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid {...gridItemProps} md={12}>
          <TextField
            control={control}
            name={DESCRIPTION}
            label={t(DESCRIPTION)}
            required={checkIfRequired(DESCRIPTION)}
            inputVariant={InputVariant.BOLD}
            multiline
            sx={{
              '& .MuiInputBase-root': { minHeight: '68px' },
            }}
          />
        </Grid>
        <AttachmentGrid {...gridItemProps} md={12}>
          <FileInputField
            control={control}
            name={ATTACHMENT}
            label={t(ATTACHMENT)}
            required={checkIfRequired(ATTACHMENT)}
          />
        </AttachmentGrid>
        <CheckboxGrid item xs={12} md={8}>
          <CheckboxField
            control={control}
            name={DATA_PROTECTION}
            label={t(DATA_PROTECTION)}
          />
          <StyledLink href={documentRedirLink} target="_blank">
            {t('dataProtectionLink')}
          </StyledLink>
        </CheckboxGrid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <AppButtonStyled
            variant="contained"
            color="secondary"
            type="submit"
          >
            {t('createTicket')}
          </AppButtonStyled>
        </Grid>
      </Grid>
    </form>
  );
};

export default HelpForm;
