import { styled, Typography } from '@mui/material';
import AppImage from 'components/appImage';
import { COLORS } from 'theme/colors';
interface ContactBoxProps {
  name: string;
  logo: string;
}

const InfoWrapper = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
}));

const LogoWrapper = styled('div')(() => ({
  height: '30%',
}));
const TextWrapper = styled('div')(() => ({}));

const PortalBox: React.FC<ContactBoxProps> = ({ name, logo }) => {
  return (
    <InfoWrapper>
      <LogoWrapper>
        <Typography sx={{ height: '100%' }} component="div">
          <AppImage path={logo} />
        </Typography>
      </LogoWrapper>
      <TextWrapper>
        <Typography
          color={COLORS.DARK_BLUE.MAIN}
          fontSize="1.2rem"
          lineHeight="1.4rem"
          textAlign="center"
        >
          {name}
        </Typography>
      </TextWrapper>
    </InfoWrapper>
  );
};

export default PortalBox;
