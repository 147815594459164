import {
  styled,
  Container,
  Typography,
  useTheme,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PTO, TLocalizedLink } from 'types/appTypes';
import { getPtoList } from 'api';

const FooterBox = styled('footer')(({ theme: { palette } }) => ({
  backgroundColor: palette.background.lightGrey.light,
  borderTop: `1px solid ${palette.border.darkGrey}`,
  marginTop: 'auto',
}));

const FooterContainer = styled(Container)(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: spacing(1),
      textAlign: 'center',
      padding: spacing(2),
    },
  }),
);

const FooterLink = styled(Link)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.lightGrey.main,
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '0.87rem',
    fontWeight: 500,
    margin: spacing(0, 0.5),
  }),
);

const LinksWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginLeft: spacing(-0.5),
}));

export const Footer = () => {
  const { palette } = useTheme();
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'footer',
  });
  const [data, setData] = useState<PTO[]>([]);
  const [documentRedirLink, setDocumentRedirLink] = useState({
    dataProtection: '',
    termsAndConditions: '',
  });

  const getLocalizedUrl = (value: TLocalizedLink) => {
    if (i18n.resolvedLanguage in value) {
      return value[i18n.resolvedLanguage];
    } else {
      return value['default'];
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const dataProtection = data.find(
    (row) => row.dataprotection_document,
  );

  const termsAndConditions = data.find(
    (row) => row.terms_and_conditions_document,
  );

  useEffect(() => {
    if (dataProtection) {
      setDocumentRedirLink((prev) => ({
        ...prev,
        dataProtection: getLocalizedUrl(
          dataProtection.dataprotection_document,
        ),
      }));
    } else {
      if (i18n.resolvedLanguage === 'hu') {
        setDocumentRedirLink((prev) => ({
          ...prev,
          dataProtection:
            'https://bkk.hu/magunkrol/jogi-nyilatkozat/',
        }));
      } else {
        setDocumentRedirLink((prev) => ({
          ...prev,
          dataProtection:
            'https://bkk.hu/en/about-bkk/legal-disclaimer/',
        }));
      }
    }

    if (termsAndConditions) {
      setDocumentRedirLink((prev) => ({
        ...prev,
        termsAndConditions: getLocalizedUrl(
          termsAndConditions.terms_and_conditions_document,
        ),
      }));
    } else {
      if (i18n.resolvedLanguage === 'hu') {
        setDocumentRedirLink((prev) => ({
          ...prev,
          termsAndConditions:
            'https://bkk.hu/jegyek-es-berletek/utazasi-feltetelek/',
        }));
      } else {
        setDocumentRedirLink((prev) => ({
          ...prev,
          termsAndConditions:
            'https://bkk.hu/en/tickets-and-passes/travel-terms-and-conditions/',
        }));
      }
    }
  }, [i18n.resolvedLanguage, dataProtection, termsAndConditions]);

  const loadData = async () => {
    const result = await getPtoList();
    if (result) setData(result);
  };

  const year = new Date().getFullYear();
  return (
    <FooterBox>
      <FooterContainer>
        <LinksWrapper>
          <FooterLink
            target="_blank"
            href={documentRedirLink.dataProtection}
          >
            {t('disclaimers')}
          </FooterLink>
          <Typography
            variant="caption"
            color={palette.border.darkGrey}
          >
            |
          </Typography>
          <FooterLink
            target="_blank"
            href={documentRedirLink.termsAndConditions}
          >
            {t('terms')}
          </FooterLink>
        </LinksWrapper>
        <div>
          <Typography
            variant="caption"
            color={palette.text.lightGrey.main}
          >
            {t('copy', { year })}
          </Typography>
        </div>
      </FooterContainer>
    </FooterBox>
  );
};

export default Footer;
