import { DatePicker } from '@mui/lab';
import AppTextField from 'components/form/textField';
import AppSelect from 'components/form/select';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, useTheme } from '@mui/material';
import { getDevicesList, getPtoList } from 'api';
import { Device, PTO } from 'types/appTypes';
import dayjs from 'dayjs';
import AppButton from 'components/appButton';
import { getLocalizedPlaceholder } from 'utils/funcs/formatDate';

const DATE_FROM = 'dateFrom';
const DATE_TO = 'dateTo';
const OPERATOR_ID = 'opertatorId';
const DEVICE_ID = 'deviceId';
const STATUS = 'status';

interface FiltersValues {
  dateFrom: dayjs.Dayjs | null;
  dateTo: dayjs.Dayjs | null;
  opertatorId: string;
  deviceId: number;
  status: string;
}

const InitialState: FiltersValues = {
  dateFrom: dayjs().subtract(14, 'day'),
  dateTo: dayjs(),
  opertatorId: '',
  deviceId: -1,
  status: '',
};

const PayWindowStatusFilter = [
  'Open',
  'Paid',
  'Pending',
  'ToSettle',
] as const;

interface FilterListsInterface {
  devices: Device[];
  operators: PTO[];
  statuses: typeof PayWindowStatusFilter;
}

const initialFilterLists: FilterListsInterface = {
  devices: [],
  operators: [],
  statuses: PayWindowStatusFilter,
};

const useFilters = () => {
  const theme = useTheme();
  const [filterLists, setFilterLists] =
    useState<FilterListsInterface>({
      ...initialFilterLists,
    });

  const [filteredValues, setFilteredValues] =
    useState<FiltersValues>(InitialState);

  const [filterValues, setFilterValues] =
    useState<FiltersValues>(InitialState);

  const { t } = useTranslation('common', {
    keyPrefix: 'components.filters',
  });

  const onSearchHandler = () => {
    setFilterValues({
      ...filteredValues,
    });
  };

  const clearFilter = () => {
    setFilteredValues({ ...InitialState }),
      setFilterValues({ ...InitialState });
  };

  const loadFilterLists = useCallback(async () => {
    const data = await Promise.all([getPtoList(), getDevicesList()]);
    setFilterLists({
      ...filterLists,
      devices: data[1] ?? [],
      operators: data[0] ?? [],
    });
  }, []);

  useEffect(() => {
    loadFilterLists();
  }, []);

  return {
    filterValues,
    filterComponent: (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: ['column', 'row'],
          flexWrap: 'wrap',
          alignItems: 'baseline',
          gap: 2,
          justifyContent: 'space-evenly',
        }}
      >
        <DatePicker
          label={t(DATE_FROM)}
          value={filteredValues[DATE_FROM]}
          onChange={(newValue) => {
            if (newValue)
              setFilteredValues({
                ...filteredValues,
                [DATE_FROM]: newValue,
              });
          }}
          renderInput={(params) => (
            <AppTextField
              fullWidth
              required
              {...params}
              style={{ maxWidth: '170px' }}
              inputProps={{
                ...params.inputProps,
                placeholder: getLocalizedPlaceholder(),
              }}
            />
          )}
        />
        <DatePicker
          label={t(DATE_TO)}
          value={filteredValues[DATE_TO]}
          onChange={(newValue) => {
            if (newValue)
              setFilteredValues({
                ...filteredValues,
                [DATE_TO]: newValue,
              });
          }}
          renderInput={(params) => (
            <AppTextField
              fullWidth
              required
              {...params}
              style={{ maxWidth: '170px' }}
              inputProps={{
                ...params.inputProps,
                placeholder: getLocalizedPlaceholder(),
              }}
            />
          )}
        />
        <AppSelect
          fullWidth
          required
          displayEmpty
          label={t('operator')}
          value={filteredValues[OPERATOR_ID]}
          renderValue={(value) => {
            const selectedOperator = filterLists.operators.find(
              (operator) => operator.id === value,
            );
            return (
              <>
                {filterLists.operators.length < 2
                  ? filterLists.operators[0]?.name
                  : selectedOperator?.name || t('allOperators')}
              </>
            );
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue && typeof newValue === 'string') {
              setFilteredValues({
                ...filteredValues,
                [OPERATOR_ID]: newValue,
              });
            }
          }}
        >
          {filterLists.operators.length > 1 && (
            <MenuItem key={-1} value={InitialState.opertatorId}>
              {t('allOperators')}
            </MenuItem>
          )}
          {filterLists.operators.map((operator, index) => (
            <MenuItem key={index} value={operator.id}>
              {operator.name}
            </MenuItem>
          ))}
        </AppSelect>
        <AppSelect
          fullWidth
          required
          displayEmpty
          label={t('device')}
          value={filteredValues[DEVICE_ID]}
          renderValue={(value) => {
            const selectedDevice = filterLists.devices.find(
              (device) => parseInt(device.id) === value,
            );
            return <>{selectedDevice?.name || t('allDevices')}</>;
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue || newValue === InitialState.deviceId) {
              setFilteredValues({
                ...filteredValues,
                [DEVICE_ID]: parseInt(newValue as unknown as string),
              });
            }
          }}
        >
          <MenuItem key={-1} value={InitialState.deviceId}>
            {t('allDevices')}
          </MenuItem>
          {filterLists.devices.map((device, index) => (
            <MenuItem key={index} value={device.id}>
              {device.name}
            </MenuItem>
          ))}
        </AppSelect>
        <AppSelect
          fullWidth
          required
          displayEmpty
          label={t('status')}
          value={filteredValues[STATUS]}
          renderValue={(value) => {
            if (typeof value === 'string') {
              return value.length > 0
                ? t(`statusLabel.${value}`)
                : t('allStatuses');
            }
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            if (
              (newValue && typeof newValue === 'string') ||
              newValue === InitialState.status
            ) {
              setFilteredValues({
                ...filteredValues,
                [STATUS]: newValue,
              });
            }
          }}
        >
          <MenuItem key={-1} value={InitialState.status}>
            {t('allStatuses')}
          </MenuItem>
          {filterLists.statuses.map((status, index) => (
            <MenuItem key={index} value={status}>
              {t(`statusLabel.${status}`)}
            </MenuItem>
          ))}
        </AppSelect>
        <AppButton
          sx={{ alignSelf: 'end', marginBottom: '5px' }}
          color="secondary"
          variant="contained"
          onClick={() => onSearchHandler()}
        >
          {t('search')}
        </AppButton>
        <AppButton
          sx={{
            alignSelf: 'end',
            marginBottom: '5px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.border.darkGrey}`,
            boxShadow: 'none',
          }}
          color="inherit"
          variant="outlined"
          onClick={() => clearFilter()}
        >
          {t('clearFilters')}
        </AppButton>
      </Box>
    ),
  };
};

export default useFilters;
