import {
  styled,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import FormField from '../formField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppInputBase from '../appInputBase';
import { InputVariant } from 'types/appEnums';
import { COLORS } from 'theme/colors';

export interface Props extends SelectProps {
  errorText?: string;
  fontWeight?: number;
  inputVariant?: InputVariant;
}

const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: (prop) =>
    prop !== 'fontWeight' && prop !== 'inputVariant',
})<{
  fontWeight?: number;
  inputVariant?: InputVariant;
}>(
  ({ fontWeight, inputVariant, theme: { typography, spacing } }) => ({
    '&.MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
      ...typography.h6,
      fontSize: '0.79rem',
      fontWeight: fontWeight || 'bold',
    },
    '&.MuiInputBase-root': {
      padding: 0,
      fontSize: '0.79rem',
      fontWeight: fontWeight || 500,
    },
    '&.MuiInputBase-root .MuiInputBase-input': {
      paddingTop: spacing(
        inputVariant === InputVariant.LIGHT ? 1.5 : 2,
      ),
      paddingBottom: spacing(
        inputVariant === InputVariant.LIGHT ? 1.5 : 2,
      ),
      textAlign: 'left',
    },
    '& .MuiList-root': {
      maxHeight: 250,
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
      borderColor: COLORS.LIGHT_GREY.DARK,
      '&:hover': {
        borderColor: COLORS.LIGHT_GREY.DARK,
      },
    },
  }),
);

const Placeholder = styled('span')(() => ({
  color: '#B3B8C7',
}));

const AppSelect = ({
  label,
  error,
  errorText,
  required,
  children,
  placeholder,
  renderValue = (selected) => <>{selected}</>,
  fontWeight,
  inputVariant,
  ...rest
}: Props) => {
  return (
    <FormField
      inputVariant={inputVariant}
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      disabled={rest.disabled}
    >
      <StyledSelect
        inputVariant={inputVariant}
        displayEmpty={!!placeholder}
        IconComponent={KeyboardArrowDownIcon}
        fullWidth
        input={<AppInputBase />}
        error={error}
        native={false}
        renderValue={(selected) => {
          if (`${selected}`.length === 0 && placeholder)
            return <Placeholder>{placeholder}</Placeholder>;

          return <>{renderValue(selected)}</>;
        }}
        fontWeight={fontWeight}
        {...rest}
      >
        {children}
      </StyledSelect>
    </FormField>
  );
};

export default AppSelect;
