import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonProps, Menu, MenuItem } from '@mui/material';

import AppButton from 'components/appButton';
import { useState } from 'react';

// Button which opens selectable menu on press
const AppMenuButton: React.FC<
  ButtonProps & {
    menuItems: (
      | { onClick: () => void; label: string | React.ReactNode }
      | JSX.Element
    )[];
  }
> = ({ menuItems, children, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppButton
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        {...rest}
      >
        {children}
      </AppButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((item, index) => {
          if ('label' in item) {
            return (
              <MenuItem
                key={index}
                onClick={item.onClick}
                disableRipple
              >
                {item.label}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={index} disableRipple>
              {item}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AppMenuButton;
