import { Control, Controller } from 'react-hook-form';
import Checkbox, { Props as CheckboxProps } from '../checkbox';

type Props = {
  control: Control<any, object>; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
} & CheckboxProps;

const CheckboxField = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <Checkbox
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          errorText={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default CheckboxField;
