import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from 'stores/store';
import { Suspense, useEffect } from 'react';
import FullscreenLoader from 'components/fullscreenLoader';
import AppLocalizer from 'pages/appLocalizer';
import { saveToLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';
import { portalsType } from 'types/appTypes';

function App() {
  useEffect(() => {
    //Redirect to gateway if url includes portal's path

    const currentPath = window.location.pathname;
    const portals: portalsType[] = JSON.parse(
      process.env.REACT_APP_API_AUTH_PORTALS ?? '',
    );

    const setGatewayParam = (param: string) => {
      window.location.href = `/?gateway=${param}`;
    };

    if (portals) {
      portals.map((el: portalsType) => {
        if (currentPath.includes(el.portal)) {
          const gatewayParam =
            currentPath.startsWith('/') && currentPath.length > 1
              ? currentPath.substring(1)
              : null;
          if (el.portal === gatewayParam) {
            return setGatewayParam(el.gateway);
          }
        }
      });
    }

    // Vlozenie parametra z URL do localstorage na dalsie pouzitie v authenticationApi.ts

    const gatewayQueryParam = new URL(
      window.location.href,
    ).searchParams.get('gateway');
    if (gatewayQueryParam) {
      saveToLocalStorage({ key: GATEWAY, value: gatewayQueryParam });
    }
  }, []);

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <CssBaseline />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppLocalizer />
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
