import { styled, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { getPtoList } from 'api';
import ContactBox from 'components/contactBox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PTO } from 'types/appTypes';

const BoxesWrapper = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacing(4),
    marginTop: spacing(5),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const ContactWhiteBox = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    height: '372px',
    width: '362px',
    maxWidth: '100%',
    background: palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(9, 10, 5, 10),
  }),
);

const Contacts = () => {
  const [data, setData] = useState<PTO[]>([]);
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.contacts',
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const result = await getPtoList();
    if (result) setData(result);
  };
  return (
    <>
      <Typography variant="h4">{t(`pageTitle`)}</Typography>
      <BoxesWrapper>
        {data.map((contact, index) => (
          <ContactWhiteBox key={index}>
            <ContactBox
              id={contact.id}
              name={contact.name}
              phone={contact.phone}
              email={contact.email}
              logo={contact.logo}
            />
          </ContactWhiteBox>
        ))}
      </BoxesWrapper>
    </>
  );
};

export default Contacts;
