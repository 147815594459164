import {
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import loadingLogo from 'assets/images/switchio_loading_logo.svg';
import masterCardSwitchio from 'assets/images/mastercard-switchio.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { LOGIN_URL, ROOT_URL } from './appRoutesConst';
import { useAppDispatch, useAppSelector } from 'stores/store';
import { changeAuthenticatedState } from 'stores/slices/userSlice';
import useAuthentication from 'utils/hooks/useAuthentication';
import { useTranslation } from 'react-i18next';

const Layout = styled('div')(({ theme: { palette } }) => ({
  background: palette.background.lightGrey.main,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const Footer = styled('div')(({ theme: { palette, spacing } }) => ({
  background: palette.common.white,
  height: spacing(24),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Content = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ImgWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginLeft: spacing(2),
  marginRight: spacing(2),
}));

const LogOutButton = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: spacing(2),
  marginLeft: spacing(2),
  marginRight: spacing(2),
}));

const LoadingPage = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.user.isAuthenticated,
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const { checkIfLogged, logOut } = useAuthentication();
  const { t } = useTranslation('common');

  useEffect(() => {
    redirect();
  }, []);

  useEffect(() => {
    if (isAuthenticated && !loading) navigate(ROOT_URL);
  }, [isAuthenticated, loading]);

  const redirect = async () => {
    const isLogged = await checkIfLogged();

    if (isLogged) {
      dispatch(changeAuthenticatedState(true));
      setLoading(false);
    } else {
      dispatch(changeAuthenticatedState(false));
      logOut();
    }
  };

  const logOutFromLoading = async () => {
    const isLogged = await checkIfLogged();

    if (isLogged) {
      dispatch(changeAuthenticatedState(false));
      logOut();
    } else {
      navigate(LOGIN_URL);
    }
  };

  return (
    <Layout>
      <LogOutButton>
        <Typography
          onClick={logOutFromLoading}
          style={{ cursor: 'pointer' }}
        >
          {t(`navigation.logout`)}
          <IconButton>
            <LogoutIcon />
          </IconButton>
        </Typography>
      </LogOutButton>
      <Content>
        <ImgWrapper>
          <img style={{ maxWidth: '100%' }} src={loadingLogo} />
        </ImgWrapper>
        <div>
          {loading && (
            <CircularProgress
              sx={{
                mt: 10,
                '&.MuiCircularProgress-root': {
                  color: theme.palette.grey[500],
                },
              }}
            />
          )}
        </div>
      </Content>
      <Footer>
        <ImgWrapper>
          <img
            style={{ maxWidth: '100%' }}
            src={masterCardSwitchio}
          />
        </ImgWrapper>
      </Footer>
    </Layout>
  );
};

export default LoadingPage;
