import OverviewHeader from 'components/overview/overviewHeader';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, styled, Typography } from '@mui/material';
import {
  formatDateForBackend,
  formatDateToDay,
  formatDateToTime,
} from 'utils/funcs/formatDate';
import AppLink from 'components/appLink/appLink';
import ActionButton from 'components/actionButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppButton from 'components/appButton';
import { formatIntegerToLocalString } from 'utils/funcs/formatNumber';
import { theme } from 'theme';
import { NoticeIcon } from 'components/noticeIcon';
import { Fragment, useEffect, useState } from 'react';
import {
  getPayWindows,
  postClearPayWindowDebt,
  getPayWindowReceipt,
  getRideReceipt,
  PaginatedData,
} from 'api';
import {
  DeviceTypes,
  PayWindow,
  PayWindowGroup,
  Ride,
} from 'types/appTypes';
import AppTooltip from 'components/appTooltip';
import { useNavigate } from 'react-router';
import {
  DOWNLOAD_RECEIPT_OR_INVOICE_PAY_WINDOW,
  DOWNLOAD_RECEIPT_OR_INVOICE_JOURNEY,
  HELP_URL,
} from './appRoutesConst';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';
import AppMenuButton from 'components/appMenuButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppImage from 'components/appImage';
import Lodash from 'lodash';
import { IssueTypes } from 'types/consts';
import useFilters from 'utils/hooks/useFilters';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ExportRidesDialog } from 'components/dialogs/exportRidesDialog';
import { COLORS } from 'theme/colors';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as WatchIcon } from 'assets/icons/watch.svg';
import { ReactComponent as SmartphoneIcon } from 'assets/icons/smartphone.svg';
import dayjs from 'dayjs';
import Spinner from 'components/spinner';

const LOAD_BY_AMOUNT = 5;

const RideBox = styled('div')(({ theme: { palette, spacing } }) => ({
  background: palette.common.white,
  margin: spacing(5, 0),
  borderRadius: '8px',
}));

const Row = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(2, 3),
}));

const RideElement = styled('div')(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    padding: spacing(2, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.background.lightGrey.dark}`,
    ':nth-of-type(odd)': {
      background: palette.background.lightGrey.light,
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

const LogoWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginRight: spacing(2),
  maxWidth: '25%',
  maxHeight: '100%',
}));

const RideDetailWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    width: '50%',
    [breakpoints.down('sm')]: { width: '100%' },
  }),
);

const PaymentDeviceWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    textAlign: 'center',
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: { textAlign: 'left', width: '100%' },
  }),
);

const LogoNameWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  }),
);

const RideBoxHeader = styled(Row)(({ theme: { breakpoints } }) => ({
  borderBottom: `1px solid ${theme.palette.background.lightGrey.dark}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [breakpoints.down('sm')]: {
    alignItems: 'start',
  },
}));

const RideBoxFooter = styled(Row)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(4),
    paddingBottom: 0,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

const FooterPayWindowWrapper = styled(Row)(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingTop: 0,
    paddingBottom: spacing(1.25),
    [breakpoints.down('sm')]: {
      paddingTop: spacing(2),
    },
  }),
);

const GrayFrame = styled('span')(({ theme: { spacing } }) => ({
  padding: spacing(0.5, 0.75),
  marginRight: '8px',
  border: '1px solid #BABABA',
  color: '#BABABA',
  fontSize: '0.75rem',
  borderRadius: 4,
}));

const TooltipWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'price',
})<{ price: number }>(({ theme: { spacing }, price }) => ({
  marginLeft: spacing(1),
  display: 'flex',
  visibility:
    typeof price === 'number' && price === 0 ? 'visible' : 'hidden',
}));

const FooterButtonWrapper = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    gap: spacing(2),
    [breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
);

const FooterPriceWrapper = styled('div')(
  ({ theme: { spacing, breakpoints, typography, palette } }) => ({
    ...typography.h5,
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
    [breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
      padding: spacing(3, 2),
      background: palette.background.lightGrey.main,
      borderRadius: '8px',
      marginBottom: spacing(4),
    },
  }),
);

const PayWindowsWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginTop: spacing(7),
}));

const ActionButtonLabel = styled('span')(
  ({ theme: { spacing } }) => ({
    color: COLORS.GREY.MAIN,
    marginLeft: spacing(0.5),
  }),
);

const ClearDialog: React.FC<{
  isOpen: boolean;
  closeFunction: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ isOpen, closeFunction }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'components.modals.clear',
  });

  return (
    <Dialog
      open={isOpen}
      onClose={closeFunction}
      aria-labelledby="clear-dialog-title"
      aria-describedby="clear-dialog-description"
    >
      <DialogTitle id="clear-dialog-title">
        {t('title')}
        <IconButton
          aria-label="close"
          onClick={closeFunction}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.text.lightGrey.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="clear-dialog-description">
          {t('description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: theme.spacing(1, 3, 3),
        }}
      >
        <AppButton
          color="secondary"
          variant="contained"
          onClick={closeFunction}
          autoFocus
        >
          {t('buttonText')}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

const PayWindowButton: React.FC<{
  status: PayWindow['status'];
  cleared: PayWindow['cleared'];
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ status, onClick, cleared }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.rides.statusAction',
  });
  const clearingProceeding = status === 'Debt' && cleared === 1;

  return (
    <AppButton
      color="secondary"
      variant="contained"
      disabled={clearingProceeding}
      onClick={onClick}
    >
      {t(clearingProceeding ? 'Clearing' : status)}
    </AppButton>
  );
};

const RideHeader: React.FC<{
  pto: PayWindow['pto'];
  t: (text: string) => string;
}> = ({ pto }) => {
  const navigate = useNavigate();
  const { t: commonT } = useTranslation('common');
  return (
    <RideBoxHeader>
      <LogoNameWrapper>
        <LogoWrapper>
          <AppImage path={pto.logo} />
        </LogoWrapper>
        <Typography
          variant="h5"
          color={'secondary.light'}
          component="span"
        >
          {pto.name}
        </Typography>
      </LogoNameWrapper>
      <div>
        <ActionButton
          menuItems={IssueTypes.map((issue, index) => ({
            key: index,
            label: commonT(issue.label),
            onClick: () =>
              navigate(HELP_URL, {
                state: {
                  currentPto: pto,
                  currentCategory: commonT(issue.label),
                },
              }),
          }))}
        />
      </div>
    </RideBoxHeader>
  );
};

const clearPayWindow = async (payWindow: PayWindow) => {
  const changedPayWindow = await postClearPayWindowDebt(payWindow.id);
  return changedPayWindow;
};

export type SortPayWindowGroupAndReplaceClearedWindow = (
  payWindowToReplaceWith: PayWindow,
  originalPayWindows: PayWindowGroup[],
) => PayWindowGroup[];

const findAndReplacePaywindowTest: SortPayWindowGroupAndReplaceClearedWindow =
  (payWindowToReplaceWith, originalPayWindows) => {
    const newData = Lodash.cloneDeep(originalPayWindows);
    if (originalPayWindows.length > 0) {
      originalPayWindows.forEach((day, dayIndex) => {
        const { payWindows } = day;
        const payWindowIndex = payWindows.findIndex(
          (payWindow) => payWindow.id === payWindowToReplaceWith.id,
        );
        if (payWindowIndex > -1) {
          newData[dayIndex].payWindows[payWindowIndex] =
            payWindowToReplaceWith;
          return newData;
        }
      });
    }
    return newData;
  };

const RideFooter: React.FC<{
  payWindow: PayWindow;
  t: (text: string) => string;
  forceReload?: () => void;
  clearPayWindowCallBack: (
    payWindowToReplaceWith: PayWindow,
    findAndReplacePaywindowTest: SortPayWindowGroupAndReplaceClearedWindow,
  ) => void;
}> = ({ clearPayWindowCallBack, forceReload, payWindow, t }) => {
  const {
    status,
    price_currency: totalCurrency,
    price_total: priceTotal,
    id,
    cleared,
  } = payWindow;
  const navigate = useNavigate();
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  const menuItems = [
    {
      label: (
        <>
          {t('downloadedReceipt')}
          <ActionButtonLabel>{t('csv')}</ActionButtonLabel>
        </>
      ),
      onClick: async () =>
        await getPayWindowReceipt(
          'csv',
          id,
          'receipt',
          `language=${resolvedLanguage}`,
        ),
    },
    {
      label: (
        <>
          {t('downloadedReceipt')}
          <ActionButtonLabel>{t('pdf')}</ActionButtonLabel>
        </>
      ),
      onClick: async () =>
        await getPayWindowReceipt('pdf', id, 'receipt'),
    },
    {
      label: t('invoice'),
      onClick: () =>
        navigate(
          `${DOWNLOAD_RECEIPT_OR_INVOICE_PAY_WINDOW}/${payWindow.id}`,
        ),
    },
  ].filter(
    (item) =>
      loadFromLocalStorage({ key: GATEWAY }) === 'hu' ||
      item.label !== t('invoice'),
  );

  const [openClearDialog, setOpenClearDialog] = useState(false);

  const handleClickOpenClearDialog = () => {
    setOpenClearDialog(true);
  };

  const handleClickCloseClearDialog = async (
    payWindow: PayWindow,
  ) => {
    const clearedPayWindow = await clearPayWindow(payWindow);
    if (clearedPayWindow) {
      clearPayWindowCallBack(
        clearedPayWindow,
        findAndReplacePaywindowTest,
      );
      if (forceReload) {
        forceReload();
      }
    }
    setOpenClearDialog(false);
  };

  let button = null;

  const receiptsButton = (
    <AppMenuButton
      menuItems={menuItems}
      color="secondary"
      variant="contained"
    >
      {t('receipts')}
    </AppMenuButton>
  );

  const DebtButtons = (
    <>
      <PayWindowButton
        cleared={cleared}
        status={'Debt'}
        onClick={handleClickOpenClearDialog}
      />
      <ClearDialog
        isOpen={openClearDialog}
        closeFunction={() => handleClickCloseClearDialog(payWindow)}
      />
      {loadFromLocalStorage({ key: GATEWAY }) === 'hu' &&
        receiptsButton}
    </>
  );

  switch (status) {
    case 'Closed':
      button = receiptsButton;
      break;
    case 'DebtManual':
      button = receiptsButton;
      break;
    case 'DebtAdviceSucess':
      button = receiptsButton;
      break;
    case 'DebtWeb':
      button = receiptsButton;
      break;
    case 'DebtFinalWeb':
      button = receiptsButton;
      break;
    case 'DebtFinalManual':
      button = receiptsButton;
      break;
    case 'Debt':
      button = (
        <>
          <PayWindowButton
            cleared={cleared}
            status={status}
            onClick={handleClickOpenClearDialog}
          />
          <ClearDialog
            isOpen={openClearDialog}
            closeFunction={() =>
              handleClickCloseClearDialog(payWindow)
            }
          />
          {loadFromLocalStorage({ key: GATEWAY }) === 'hu' &&
            receiptsButton}
        </>
      );
      break;
    case 'DebtSettled':
      button = DebtButtons;
      break;
    case 'DebtFinal':
      button = DebtButtons;
      break;
    case 'DebtAdviceFail':
      button = DebtButtons;
      break;
    case 'AuthDeclined':
      button = DebtButtons;
      break;
    case 'DebtSettledFail':
      button = DebtButtons;
      break;
    default:
      if (loadFromLocalStorage({ key: GATEWAY }) === 'hu') {
        button = receiptsButton;
      }
  }

  return (
    <>
      <RideBoxFooter>
        <FooterPriceWrapper>
          <div>{t('total')}</div>
          <Typography
            component="div"
            variant="h4"
            sx={{ textTransform: 'capitalize', marginLeft: 2 }}
          >
            {/* {`${formatIntegerToLocalString(priceTotal)}`} */}
            {`${formatIntegerToLocalString(
              priceTotal / 100,
            )} ${totalCurrency}`}
          </Typography>
        </FooterPriceWrapper>
        <FooterButtonWrapper>
          {status && <IconWithInfo status={status} />}
          {status && button}
        </FooterButtonWrapper>
      </RideBoxFooter>
      <FooterPayWindowWrapper>
        <GrayFrame>{`${t('payWindowId')} ${payWindow.id}`}</GrayFrame>
        {payWindow.prn && (
          <GrayFrame>{`${t('prn')} ${payWindow.prn}`}</GrayFrame>
        )}
      </FooterPayWindowWrapper>
    </>
  );
};

const DeviceTypeIcon = ({
  deviceType,
}: {
  deviceType: DeviceTypes;
}) => {
  switch (deviceType) {
    case 'Card':
      return <CardIcon style={{ position: 'relative', top: 2 }} />;
    case 'Smartwatch':
      return <WatchIcon style={{ position: 'relative', top: 1 }} />;
    case 'Smartphone':
      return (
        <SmartphoneIcon style={{ position: 'relative', top: 2 }} />
      );
  }
};

const PayWindowRides: React.FC<{
  payWindow: PayWindow;
  t: (text: string) => string;
}> = ({ payWindow, t }) => {
  const navigate = useNavigate();

  const ridesByTapId = payWindow.rides.reduce(
    (acc: Record<string, Ride[]>, ride) => {
      const { tap_id, ...rideProps } = ride;
      if (!acc[tap_id]) {
        acc[tap_id] = [{ ...rideProps, tap_id }];
      } else {
        acc[tap_id].push({ ...rideProps, tap_id });
      }
      return acc;
    },
    {},
  );

  const summedRides = Object.entries(ridesByTapId)
    .map(([tap_id, rides]) => {
      const price = rides.reduce(
        (total, ride) => total + ride.price,
        0,
      );
      return {
        ...rides[0],
        tap_id,
        price,
        singlePrice: rides[0].price,
        count: rides.length,
      };
    })
    .sort(
      (a, b) =>
        new Date(a.start).getTime() - new Date(b.start).getTime(),
    );

  const generateButtonsForRide = (ride: Ride) => {
    const buttons = [];
    // receipt
    if (payWindow.status !== 'Open') {
      buttons.push({
        label: t('action.downloadReceipt'),
        onClick: async () => {
          await getRideReceipt(
            'pdf',
            ride.id,
            t('downloadedReceipt'),
          );
        },
      });
    }
    // invoice
    if (loadFromLocalStorage({ key: GATEWAY }) === 'hu') {
      buttons.push({
        label: t('action.downloadInvoice'),
        onClick: () =>
          navigate(
            `${DOWNLOAD_RECEIPT_OR_INVOICE_JOURNEY}/${ride.id}`,
          ),
      });
    }

    return buttons;
  };

  return (
    <>
      {summedRides.map((ride, index) => {
        return (
          <RideElement key={index}>
            <RideDetailWrapper>
              <Typography
                component="div"
                variant="subtitle1"
                fontWeight="bold"
                color="secondary"
              >
                {/* Prefered format is [reason] : [start_station] - [end_station]. If end_station is not known, show start_station. If start_station is not known either, show line or nothing.*/}
                {`${ride.reason ? ride.reason + ' :' : ''}
                ${
                  ride.start_station && ride.end_station
                    ? ride.start_station + ' - ' + ride.end_station
                    : ride.start_station
                    ? ride.start_station
                    : ride.line
                    ? ride.line
                    : ''
                }`}
              </Typography>
              <Typography variant="body2" component="div">
                {`${formatDateToDay(ride.start)} |
          ${formatDateToTime(ride.start, 'HH:mm')} ${
                  (ride.end &&
                    `- ${formatDateToTime(ride.end, 'HH:mm')}`) ??
                  ''
                }`}
              </Typography>
            </RideDetailWrapper>
            <PaymentDeviceWrapper>
              <AppLink
                to={`/device/${ride.device_id}`}
                style={{
                  display: 'flex',
                }}
              >
                <DeviceTypeIcon deviceType={payWindow.device.type} />
                <span
                  style={{
                    marginLeft: 10,
                    color: COLORS.DARK_BLUE.MAIN,
                  }}
                >
                  {payWindow.device.type === 'Card'
                    ? ride.pan.slice(-9)
                    : ride.device_name}
                </span>
              </AppLink>
            </PaymentDeviceWrapper>
            <Typography
              component="div"
              variant="h6"
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifySelf: 'end',
                width: '20%',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  justifyContent: 'space-between',
                },
              })}
            >
              {ride.count > 1 &&
                `${ride.count}x ${formatIntegerToLocalString(
                  ride.singlePrice / 100,
                )} ${ride.price_currency}`}
            </Typography>
            <Typography
              component="div"
              variant="h5"
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifySelf: 'end',
                width: '20%',
                justifyContent: 'flex-end',
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  justifyContent: 'flex-end',
                },
              })}
            >
              <div
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    {formatIntegerToLocalString(ride.price / 100)}
                  </div>
                  <div>
                    <Typography
                      component="span"
                      variant="inherit"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                    >
                      {ride.price_currency}
                    </Typography>
                  </div>
                </div>
                <TooltipWrapper price={ride.price}>
                  <AppTooltip title={t('rideTooltip')} />
                </TooltipWrapper>
              </div>

              <div>
                <ActionButton
                  menuItems={generateButtonsForRide(ride)}
                  actionButtonIcon={<KeyboardArrowDownIcon />}
                />
              </div>
            </Typography>
          </RideElement>
        );
      })}
    </>
  );
};

interface PayWindowBoxProps {
  payWindow: PayWindow;
  clearPayWindowCallBack: (
    payWindowToReplaceWith: PayWindow,
    sortingFunction: SortPayWindowGroupAndReplaceClearedWindow,
  ) => void;
  forceReload?: () => void;
}

export const PayWindowBox: React.FC<PayWindowBoxProps> = ({
  payWindow,
  clearPayWindowCallBack,
  forceReload,
}) => {
  const { pto } = payWindow;
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.rides',
  });
  return (
    <RideBox>
      <RideHeader pto={pto} t={t} />
      <PayWindowRides payWindow={payWindow} t={t} />
      <RideFooter
        clearPayWindowCallBack={clearPayWindowCallBack}
        payWindow={payWindow}
        forceReload={forceReload}
        t={t}
      />
    </RideBox>
  );
};

const IconWithInfo: React.FC<{ status: PayWindow['status'] }> = ({
  status,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.rides',
  });

  if (
    status === 'Closed' ||
    status === 'DebtManual' ||
    status === 'DebtAdviceSucess' ||
    status === 'DebtWeb' ||
    status === 'DebtFinalWeb' ||
    status === 'DebtFinalManual'
  )
    return <NoticeIcon label={t(`status.Paid`)} type={'success'} />;
  if (
    status === 'Open' ||
    status === 'Moved' ||
    status === 'NoAuthDone'
  )
    return <NoticeIcon label={t(`status.Open`)} type={'info'} />;
  if (status === 'ToSettle')
    return <NoticeIcon label={t(`status.ToSettle`)} type={'info'} />;
  if (
    status === 'Debt' ||
    status === 'DebtSettled' ||
    status === 'DebtFinal' ||
    status === 'DebtAdviceFail' ||
    status === 'AuthDeclined' ||
    status === 'DebtSettledFail'
  )
    return <NoticeIcon label={t(`status.Pending`)} type={'error'} />;
  return <></>;
};

const RidesPage = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.rides',
  });
  const [data, setData] = useState<PaginatedData<PayWindowGroup[]>>({
    data: [],
    count: 0,
    limit: 0,
    offset: 0,
    next_page: false,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [forceLoad, setForceLoad] = useState(new Date());
  const [dataLimit, setDataLimit] = useState(LOAD_BY_AMOUNT);
  const [showLoader, setShowLoader] = useState(false);
  const { filterComponent: FilterComponent, filterValues } =
    useFilters();

  const clearWindowCallback = (
    payWindowToReplaceWith: PayWindow,
    sortingFunction: SortPayWindowGroupAndReplaceClearedWindow,
  ) => {
    const newData = sortingFunction(
      payWindowToReplaceWith,
      data.data,
    );
    setData({ ...data, data: newData });
  };

  useEffect(() => {
    const generateStatus = (status: string) => {
      switch (status) {
        case 'Open':
          return ['Open', 'Moved', 'NoAuthDone'];
        case 'Paid':
          return [
            'Closed',
            'DebtManual',
            'DebtAdviceSucess',
            'DebtWeb',
            'DebtFinalWeb',
            'DebtFinalManual',
          ];
        case 'ToSettle':
          return ['ToSettle'];
        case 'Pending':
          return [
            'Debt',
            'DebtSettled',
            'DebtFinal',
            'ToSettle',
            'DebtAdviceFail',
            'AuthDeclined',
            'DebtSettledFail',
          ];
        default:
          return null;
      }
    };

    const parsedFilter = {
      from: filterValues.dateFrom
        ? formatDateForBackend(filterValues.dateFrom)
        : '',
      to: filterValues.dateTo
        ? formatDateForBackend(filterValues.dateTo)
        : '',
      status: generateStatus(filterValues.status),
      pto_id: filterValues.opertatorId,
      device_id:
        filterValues.deviceId > -1
          ? filterValues.deviceId?.toString()
          : '',
    };
    loadData(false, { ...parsedFilter, limit: dataLimit });
  }, [filterValues, dataLimit, forceLoad]);

  const loadData = async (showDebt: boolean, params = {}) => {
    setShowLoader(true);
    const result = await getPayWindows(showDebt, params);
    if (result) {
      setData(result);
    }
    setShowLoader(false);
  };

  const forceReload = () => {
    setForceLoad(new Date());
  };

  return (
    <Box>
      <ExportRidesDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        startDate={
          filterValues.dateFrom ? filterValues.dateFrom : null
        }
        endDate={filterValues.dateTo ? filterValues.dateTo : null}
      />
      <OverviewHeader
        boldInfo={t('title')}
        actions={[
          {
            icon: <GetAppIcon />,
            label: t('export'),
            onClick: () => setIsDialogOpen(true),
            disabled: false,
          },
          {
            icon: null,
            label: t(isCollapsed ? 'hideFilter' : 'showFilter'),
            onClick: () => setIsCollapsed(!isCollapsed),
            disabled: false,
          },
        ]}
      />
      <Collapse in={isCollapsed}> {FilterComponent}</Collapse>
      {data.data.length < 1 && (
        <Typography
          variant="h5"
          sx={{ textAlign: 'center' }}
          component="div"
        >
          {t('noData')}
        </Typography>
      )}
      <PayWindowsWrapper>
        {data.data.map((payWindowGroup, index) => (
          <Fragment key={index}>
            <OverviewHeader
              key={index}
              info={formatDateToDay(
                dayjs(payWindowGroup.day, 'DD.MM.YYYY'),
              )}
            />

            {payWindowGroup.payWindows.map(
              (payWindow: PayWindow, index) => (
                <PayWindowBox
                  key={index}
                  payWindow={payWindow}
                  forceReload={forceReload}
                  clearPayWindowCallBack={clearWindowCallback}
                />
              ),
            )}
          </Fragment>
        ))}
      </PayWindowsWrapper>
      {data.next_page && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <AppButton
            color="secondary"
            variant="contained"
            onClick={() => setDataLimit(LOAD_BY_AMOUNT + dataLimit)}
          >
            {t('loadMore', { amount: LOAD_BY_AMOUNT })}
          </AppButton>
        </Box>
      )}
      {showLoader && <Spinner marginTop />}
    </Box>
  );
};

export default RidesPage;
