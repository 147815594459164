import AppTextField, {
  Props as TextFieldProps,
} from 'components/form/textField';
import { Control, Controller } from 'react-hook-form';
import { InputVariant } from 'types/appEnums';

type Props = {
  control?: Control<any, object>; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  inputVariant?: InputVariant;
  customOnBlur?: () => void;
  upperCased?: boolean;
  requiredSpecialText?: string;
} & TextFieldProps;

const TextField = ({
  control,
  name,
  inputVariant = InputVariant.LIGHT,
  customOnBlur,
  upperCased,
  requiredSpecialText,
  ...rest
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <AppTextField
          inputVariant={inputVariant}
          onChange={(e) => {
            if (upperCased) {
              onChange(e.target.value.toUpperCase());
              return;
            }

            onChange(e.target.value);
          }}
          onBlur={() => {
            customOnBlur && customOnBlur();
            onBlur();
          }}
          value={value}
          name={name}
          error={!!error?.message}
          errorText={error?.message}
          requiredSpecialText={requiredSpecialText}
          {...rest}
        />
      )}
    />
  );
};

export default TextField;
