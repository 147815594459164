import {
  Grid,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AppMenuButton from 'components/appMenuButton';
// import FaqContactBox from 'components/faqContactBox';
import FaqItem from 'components/faqItem';
import OverviewHeader from 'components/overview/overviewHeader';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Desc = styled(Typography)(({ theme: { spacing } }) => ({
  color: COLORS.GREY.MAIN,
  marginBottom: spacing(2.5),
  fontSize: '0.875rem',
}));

interface TabItem {
  itemTitle: string;
  itemPerex: string;
}

interface TabTranslateObject {
  tabs: {
    tabTitle: string;
    tabItems: { itemTitle: string; itemPerex: string }[];
  }[];
}

const ColumnTitle = styled('span')(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    color: palette.primary.dark,
    fontSize: '1.25rem',
    fontWeight: 700,
    marginTop: spacing(3),
    marginLeft: spacing(3),
    marginBottom: spacing(5),
    display: 'inline-block',
    [breakpoints.down('md')]: {
      margin: spacing(2.5, 0, 2, 0),
    },
  }),
);

const TabButtonStyled = styled(Tab)(
  ({ theme: { palette, spacing } }) => ({
    '&.MuiTab-root': {
      color: palette.text.lightGrey.main,
      padding: spacing(2.75, 3),
      borderRadius: spacing(1),
      fontSize: '0.875rem',
      fontWeight: 700,
      textTransform: 'none',
      textAlign: 'left',
      alignItems: 'flex-start',
      marginBottom: spacing(1),
    },
    '&.MuiTab-root.Mui-selected': {
      color: palette.secondary.main,
      backgroundColor: palette.background.paper,
      borderRadius: spacing(1),
    },
  }),
);

const AppMenuButtonStyled = styled(AppMenuButton)(
  ({ theme: { spacing } }) => ({
    color: '#F9F9F9',
    backgroundColor: COLORS.DARK_BLUE.MAIN,
    fontSize: '0.875',
    fontWeight: 700,
    borderRadius: spacing(1),
    width: '100%',
    marginBottom: spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#F9F9F9',
      backgroundColor: COLORS.DARK_BLUE.MAIN,
    },
  }),
);

const Faq: React.FC = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.faq',
  });
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const gateway = loadFromLocalStorage({ key: GATEWAY });

  const getTabs = () => {
    switch (gateway) {
      case 'hu':
        return t('faqs_hu', {
          returnObjects: true,
        }) as TabTranslateObject;
      case 'rgn':
        return t('faqs_de', {
          returnObjects: true,
        }) as TabTranslateObject;
      case 'cz':
        return t('faqs_cs', {
          returnObjects: true,
        }) as TabTranslateObject;
      case 'en':
        return t('faqs_en', {
          returnObjects: true,
        }) as TabTranslateObject;
      default:
        return t('faqs', {
          returnObjects: true,
        }) as TabTranslateObject;
    }
  };

  const { tabs } = getTabs();

  const isBellowMdBreakpoint = useMediaQuery(
    theme.breakpoints.down('md'),
  );

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Typography component="div">{children}</Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <OverviewHeader boldInfo={t('pageTitle')} />
      <Desc>{t('faqDesc')}</Desc>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <ColumnTitle>{t('title1')}</ColumnTitle>
          {isBellowMdBreakpoint && (
            <div>
              <AppMenuButtonStyled
                menuItems={tabs.map(
                  (
                    tab: {
                      tabTitle: string;
                    },
                    index: number,
                  ) => ({
                    key: index,
                    label: tab.tabTitle,
                    onClick: () => handleChange(null, index),
                  }),
                )}
              >
                {tabs.length && tabs[value].tabTitle
                  ? tabs[value].tabTitle
                  : ''}
              </AppMenuButtonStyled>
            </div>
          )}
          {!isBellowMdBreakpoint && (
            <Tabs
              orientation={
                isBellowMdBreakpoint ? 'horizontal' : 'vertical'
              }
              variant="scrollable"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              {tabs.length > 0 &&
                tabs.map(
                  (
                    tab: {
                      tabTitle: string;
                    },
                    index: number,
                  ) => (
                    <TabButtonStyled
                      key={index}
                      label={tab.tabTitle}
                    />
                  ),
                )}
            </Tabs>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <ColumnTitle>{t('title2')}</ColumnTitle>
          {tabs.length > 0 &&
            tabs.map(
              (tab: { tabItems: TabItem[] }, tabIndex: number) => {
                return (
                  <TabPanel
                    key={tabIndex}
                    value={value}
                    index={tabIndex}
                  >
                    {tab.tabItems.map(
                      (
                        item: {
                          itemTitle: string;
                          itemPerex: string;
                        },
                        index: number,
                      ) => (
                        <FaqItem
                          key={index}
                          title={item.itemTitle}
                          perex={item.itemPerex}
                        />
                      ),
                    )}
                  </TabPanel>
                );
              },
            )}
        </Grid>
      </Grid>
      {/* CONTACTBOX HIDDEN FOR FUTURE USE */}
      {/* <FaqContactBox /> */}
    </>
  );
};

export default Faq;
