import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import { COLORS } from 'theme/colors';
import { styled } from '@mui/material';

export interface FaqItemType {
  title: string;
  perex: string;
}

type FaqItemProps = FaqItemType;

const AccordionStyled = styled(Accordion)(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(1.5),
    borderRadius: spacing(1),
    ':first-of-type': {
      borderRadius: spacing(1),
    },
    ':before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      alignSelf: 'flex-start',
      position: 'relative',
      top: spacing(1.5),
    },
    '&.Mui-expanded': {
      marginBottom: spacing(3),
      marginTop: spacing(3),
    },
  }),
);

const AccordionSummaryStyled = styled(AccordionSummary)(
  ({ theme: { spacing } }) => ({
    padding: spacing(1, 3),
    '& .MuiAccordionSummary-expandIconWrapper': {
      alignSelf: 'flex-start',
      position: 'relative',
      top: spacing(1.5),
    },
  }),
);

const AccordionDetailsStyled = styled(AccordionDetails)(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing(0, 3, 5, 3),
    [breakpoints.down('md')]: {
      paddingBottom: spacing(4),
    },
  }),
);

const FaqItem: React.FC<FaqItemProps> = ({ title, perex }) => {
  const [expanded, setExpanded] = useState(false);
  const { spacing } = useTheme();
  return (
    <AccordionStyled
      expanded={expanded}
      onClick={() => setExpanded((prevState) => !prevState)}
      elevation={0}
      square
    >
      <AccordionSummaryStyled
        expandIcon={
          expanded ? (
            <RemoveIcon color={'secondary'} sx={{ fontSize: 20 }} />
          ) : (
            <AddIcon sx={{ color: COLORS.GREY.MAIN, fontSize: 20 }} />
          )
        }
      >
        <Typography
          component="span"
          color={expanded ? COLORS.DARK_BLUE.MAIN : COLORS.GREY.MAIN}
          fontSize="0.875rem"
          fontWeight={700}
          sx={{
            marginTop: spacing(expanded ? 2 : 0),
          }}
        >
          {title}
        </Typography>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <Typography
          component="span"
          fontSize="0.875rem"
          color={COLORS.GREY.MAIN}
        >
          {perex}
        </Typography>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default FaqItem;
