import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import FormField from '../formField';
import { Control, Controller } from 'react-hook-form';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';

interface Props {
  control?: Control<any, object>; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  label: string;
  required?: boolean;
}

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dragging',
})<{ dragging: boolean }>(({ dragging, theme: { spacing } }) => ({
  color: COLORS.GREY.LIGHT,
  border: `1px dashed ${
    dragging ? COLORS.DARK_BLUE.MAIN : COLORS.GREY.LIGHT
  }`,
  borderRadius: 8,
  padding: spacing(2.5),
  display: 'flex',
  justifyContent: 'center',
  fontSize: '0.875rem',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const FakeLink = styled('span')(({ theme: { spacing } }) => ({
  textDecoration: 'underline',
  fontWeight: 700,
  color: COLORS.LIGHT_BLUE.DARK,
  paddingLeft: spacing(0.75),
}));

const FileInputField = ({
  name,
  control,
  label,
  required,
}: Props) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'components.formFields',
  });
  const [dragging, setDragging] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <FormField
          label={label}
          required={required}
          error={!!error?.message}
          errorText={error?.message}
        >
          <FileUploader
            handleChange={onChange}
            name="file"
            onDraggingStateChange={(dragging: boolean) =>
              setDragging(dragging)
            }
            dropMessageStyle={{ display: 'none' }}
            multiple={false}
          >
            {}
            <Wrapper dragging={dragging}>
              {value?.name ?? (
                <>
                  {t('filesDesc')}
                  <FakeLink>{t('filesDescLink')}</FakeLink>
                </>
              )}
            </Wrapper>
          </FileUploader>
        </FormField>
      )}
    />
  );
};

export default FileInputField;
