import { styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';
import { InputVariant } from 'types/appEnums';

interface FormFieldProps {
  label: React.ReactNode;
  inputVariant?: InputVariant;
  error: boolean | undefined;
  errorText?: string;
  required: boolean | undefined;
  disabled?: boolean;
  requiredSpecialText?: string;
}

const TopInfoWrapper = styled('div')<{ inputVariant?: InputVariant }>(
  ({ inputVariant, theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(
      inputVariant === InputVariant.BOLD ? 0.7 : 0,
    ),
  }),
);

const FormField: React.FC<FormFieldProps> = ({
  children,
  label,
  error,
  errorText,
  required,
  inputVariant = InputVariant.BOLD,
  disabled,
  requiredSpecialText,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('common', {
    keyPrefix: 'components.formFields',
  });

  return (
    <div>
      <TopInfoWrapper>
        {label && (
          <Typography
            color={
              inputVariant === InputVariant.BOLD
                ? theme.palette.secondary.main
                : COLORS.GREY.MAIN
            }
            sx={{
              ...(inputVariant === InputVariant.BOLD
                ? {
                    fontWeight: 500,
                    color: COLORS.DARK_BLUE.MAIN,
                    fontSize: '1rem',
                  }
                : theme.typography.body2),
              fontWeight: '700',
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1.5),
              opacity: disabled ? 0.5 : 1,
            }}
          >
            {label}
          </Typography>
        )}
        {required && (
          <Typography
            color={COLORS.GREY.LIGHT}
            sx={{
              marginBottom: theme.spacing(1.5),
              fontWeight: 'light',
              fontSize: '0.8rem',
            }}
          >
            {requiredSpecialText
              ? `(${requiredSpecialText})`
              : `(${t('requiredField')})`}
          </Typography>
        )}
      </TopInfoWrapper>
      {children}
      {error && errorText && (
        <Typography color="error">{t(errorText)}</Typography>
      )}
    </div>
  );
};

export default FormField;
