import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip, TooltipProps } from '@mui/material';

const AppTooltip: React.FC<Omit<TooltipProps, 'children'>> = (
  props,
) => {
  return (
    <Tooltip {...props}>
      <InfoRoundedIcon color="primary" />
    </Tooltip>
  );
};

export default AppTooltip;
