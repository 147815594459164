import {
  CountryFromAPI,
  Country,
  PayWindowGroup,
  RideWithPTO,
} from 'types/appTypes';
import countriesJSON from '../countries.json';
import { loadFromLocalStorage } from 'localStorage';
import { GATEWAY } from 'localStorage/localStorageKeys';
import i18n from 'i18next';

export const getJourneysFromPayWindowGroups = (
  payWindowGroups: PayWindowGroup[],
): RideWithPTO[] => {
  return payWindowGroups.reduce<RideWithPTO[]>((acc, curr) => {
    const currJourneys = curr.payWindows.flatMap(
      ({ rides, pto_id }) =>
        rides.map((ride) => ({ ...ride, pto_id })),
    );
    return [...acc, ...currJourneys];
  }, []);
};

export const normalizeCountries = (
  countries: CountryFromAPI[],
): Country[] => {
  const gatewayValue = loadFromLocalStorage({ key: GATEWAY });

  const sortedCountries: CountryFromAPI[] = [
    countries.find(
      (item) => item.code === gatewayValue.toLocaleUpperCase(),
    ) || countries[0],
    ...countries.filter(
      (item) => item.code != gatewayValue.toLocaleUpperCase(),
    ),
  ];

  const normalizedCountries = sortedCountries.map(
    ({ code, rule }) => {
      const en =
        countriesJSON.find(
          ({ code: jsonCode }) => jsonCode === code,
        )?.['en'] || '';
      const hu =
        countriesJSON.find(
          ({ code: jsonCode }) => jsonCode === code,
        )?.['hu'] || en;
      const de =
        countriesJSON.find(
          ({ code: jsonCode }) => jsonCode === code,
        )?.['de'] || en;
      const cs =
        countriesJSON.find(
          ({ code: jsonCode }) => jsonCode === code,
        )?.['cs'] || en;
      const normalizedCountry: Country = {
        code,
        postal_code_regex: rule,
        en,
        hu,
        de,
        cs,
      };

      return normalizedCountry;
    },
  );

  return normalizedCountries;
};

export const sortCountriesByLanguage = (
  normalizedCountries: Country[],
) => {
  if (normalizedCountries.length === 0) return [];
  const gatewayValue = loadFromLocalStorage({ key: GATEWAY });
  normalizedCountries.sort((countryA, countryB) => {
    if (i18n.language == 'hu') {
      return countryA.hu > countryB.hu ? 1 : -1;
    } else if (i18n.language == 'en') {
      return countryA.en > countryB.en ? 1 : -1;
    } else if (i18n.language == 'cs') {
      return countryA.cs > countryB.cs ? 1 : -1;
    } else if (i18n.language == 'de') {
      return countryA.de > countryB.de ? 1 : -1;
    } else {
      return countryA.en > countryB.en ? 1 : -1;
    }
  });

  return [
    normalizedCountries.find(
      (item) => item.code === gatewayValue.toLocaleUpperCase(),
    ) || normalizedCountries[0],
    ...normalizedCountries.filter(
      (item) => item.code != gatewayValue.toLocaleUpperCase(),
    ),
  ];
};
