export const REGEX_NAME =
  /^[a-zA-Z0-9 ,áéíóöőúüűÁÉÍÓÖŐÚÜŰ&„’.\-_+–()/:;@[\]~{}!%=˝×€$*?ä“‚‘“”"']*$/;

export const REGEX_CITY =
  /^[a-zA-Z0-9 ,áéíóöőúüűÁÉÍÓÖŐÚÜŰ&„’.\-_+–()/:;@[\]~{}!%=˝×€$*?ä“‚‘“”"']*$/;

export const REGEX_STREET =
  /^[a-zA-Z0-9 ,áéíóöőúüűÁÉÍÓÖŐÚÜŰ&„’.\-_+–()/:;@[\]~{}!%=˝×€$*?ä“‚‘“”"']*$/;

export const REGEX_TAX = /^[A-Z0-9 \-–]*$/;

export const REGEX_EMAIL =
  /^[‚‘“”"'\w]+([.-]?[‚‘“”"'\w]+)@\w+([.-]?\w+)(.\w{2,3})+$/;
