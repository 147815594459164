import { useTranslation } from 'react-i18next';
import OverviewHeader from 'components/overview/overviewHeader';
import { useEffect, useState } from 'react';
import { Device, PayWindow, PTO, RideWithPTO } from 'types/appTypes';
import { getDevicesList, getPayWindows, getPtoList } from 'api';
import { Link, useLocation } from 'react-router-dom';
import HelpForm from 'components/helpForm';
import { Typography, styled } from '@mui/material';
import { FAQ_URL } from './appRoutesConst';
import { COLORS } from 'theme/colors';
import { getJourneysFromPayWindowGroups } from 'utils/funcs/transform';
import dayjs from 'dayjs';

interface HelpLocationState {
  currentPto?: PayWindow['pto'];
  currentCategory?: string;
}

const LinkStyled = styled(Link)(() => ({
  color: COLORS.LIGHT_BLUE.DARK,
  fontWeight: 700,
}));

const Desc = styled(Typography)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2.5),
  fontSize: '0.875rem',
}));

const HelpPage: React.FC = () => {
  const [data, setData] = useState<{
    pto: PTO[];
    devices: Device[];
  }>({
    pto: [],
    devices: [],
  });
  const [journeys, setJourneys] = useState<RideWithPTO[]>([]);
  const { state } = useLocation();
  const currentPto = (state as HelpLocationState)?.currentPto;
  const currentDate = dayjs();
  const weekAgo = currentDate.subtract(7, 'days');
  const startOfYear = currentDate.startOf('year');

  const [dateRange, setDateRange] = useState({
    from: weekAgo,
    to: currentDate,
  });
  const currentCategory = (state as HelpLocationState)
    ?.currentCategory;

  const { t } = useTranslation('common', {
    keyPrefix: 'pages.help',
  });

  const loadData = async () => {
    const list = await Promise.all([getPtoList(), getDevicesList()]);
    setData({
      ...data,
      pto: list[0] ?? [],
      devices: list[1] ?? [],
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadPW = async (limit: number, from: string, to: string) => {
    try {
      const pwData = await getPayWindows(false, { limit, from, to });
      setJourneys((prevJourneys) => [
        ...prevJourneys,
        ...getJourneysFromPayWindowGroups(pwData.data),
      ]);
    } catch (error) {
      console.error('Error fetching pay windows:', error);
    }
  };

  useEffect(() => {
    if (dateRange.from >= startOfYear.subtract(3, 'month')) {
      const from = dateRange.from.format('YYYY-MM-DD');
      const to = dateRange.to.format('YYYY-MM-DD');

      loadPW(1000, from, to);

      // Load data for the next two weeks
      for (let i = 1; i <= 5; i++) {
        const nextWeekFrom =
          i !== 5
            ? dateRange.from.subtract(i, 'week').format('YYYY-MM-DD')
            : dateRange.from
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD');
        const nextWeekTo = dateRange.to
          .subtract(i, 'week')
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
        loadPW(1000, nextWeekFrom, nextWeekTo);
      }

      // Load data for the next two months
      for (let i = 1; i <= 2; i++) {
        const nextFrom = dateRange.from
          .subtract(i + 1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        const nextTo = dateRange.to
          .subtract(i + 1, 'month')
          .subtract(1, 'day')
          .endOf('month')
          .format('YYYY-MM-DD');
        loadPW(1000, nextFrom, nextTo);
      }

      // Load data for the next 9 months (3 calls with 3-month intervals)
      for (let i = 1; i <= 7; i++) {
        const nextFrom = dateRange.from
          .subtract(3 + i * 3, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        const nextTo =
          i !== 1
            ? dateRange.to
                .subtract(1 + i * 3, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            : dateRange.to
                .subtract(i * 3 + 1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD');
        loadPW(1000, nextFrom, nextTo);
      }

      // Update date range for the next iteration
      setDateRange({
        from: dateRange.from.subtract(12, 'month'),
        to: dateRange.to.subtract(12, 'month'),
      });
    }
  }, [journeys]);

  return (
    <div>
      <OverviewHeader boldInfo={t('title')} />
      <Desc>
        {t('subTitle')}
        <LinkStyled to={FAQ_URL}>{t('faq')}</LinkStyled>.
      </Desc>
      <HelpForm
        pto={data.pto}
        devices={data.devices}
        currentPto={currentPto}
        currentCategory={currentCategory}
        journeys={journeys}
      />
    </div>
  );
};

export default HelpPage;
