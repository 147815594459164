import { styled } from '@mui/material';
import { getDevicesList, getLastPW, getPtoList } from 'api';
import ContactBox from 'components/contactBox';
import OverviewHeader from 'components/overview/overviewHeader';
import DeviceBox from 'components/deviceBox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Device,
  PayWindow,
  PayWindowGroup,
  PTO,
} from 'types/appTypes';
import { ContactWhiteBox } from './contacts';
import {
  PayWindowBox,
  SortPayWindowGroupAndReplaceClearedWindow,
} from './ridesPage';
import Lodash from 'lodash';
import { formatDateToDay } from 'utils/funcs/formatDate';
import dayjs from 'dayjs';
import Spinner from 'components/spinner';

interface DashboardState {
  contacts: PTO[];
  lastPW: PayWindowGroup[];
  devices: Device[];
}

const PAY_WINDOW_GROUP_INDEX = 0;
const PAY_WINDOW_INDEX = 0;

const BoxesWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: spacing(4),
  marginTop: spacing(5),
  marginBottom: spacing(10),
}));

const Dashboard = () => {
  const [data, setData] = useState<DashboardState>({
    contacts: [],
    lastPW: [],
    devices: [],
  });
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.dashboard',
  });

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setShowLoader(true);
    const responseData = await Promise.all([
      getPtoList(),
      getLastPW(),
      getDevicesList(),
    ]);
    setData({
      contacts: responseData[0] ?? [],
      lastPW: responseData[1]?.data ?? [],
      devices: responseData[2] ?? [],
    });
    setShowLoader(false);
  };

  const replacePayWindowWithClearedOne = (
    payWindowToReplaceWith: PayWindow,
    sortingFunction: SortPayWindowGroupAndReplaceClearedWindow,
  ) => {
    if (data.lastPW) {
      const newPayWindowGroups = sortingFunction(
        payWindowToReplaceWith,
        data.lastPW,
      );
      const newState = Lodash.cloneDeep(data);
      setData({ ...newState, lastPW: newPayWindowGroups });
    }
  };

  if (showLoader) {
    return <Spinner marginTop />;
  }

  return (
    <div>
      <OverviewHeader
        boldInfo={t('latestRide')}
        info={
          data.lastPW
            ? formatDateToDay(
                dayjs(
                  data?.lastPW[PAY_WINDOW_GROUP_INDEX]?.day,
                  'DD.MM.YYYY',
                ),
              )
            : ''
        }
        actions={{ label: t('allRides'), to: '/journeys' }}
      />
      {data?.lastPW?.map((payWindowGroup, index) => {
        if (index === PAY_WINDOW_GROUP_INDEX) {
          return payWindowGroup.payWindows.map((payWindow, index) => {
            if (index === PAY_WINDOW_INDEX)
              return (
                <PayWindowBox
                  clearPayWindowCallBack={
                    replacePayWindowWithClearedOne
                  }
                  key={index}
                  payWindow={payWindow}
                />
              );
            return null;
          });
        }

        return null;
      })}
      <OverviewHeader
        boldInfo={t('devices')}
        actions={{ label: t('allDevices'), to: '/devices' }}
      />
      <BoxesWrapper>
        {data.devices.map((device, index) => (
          <DeviceBox
            key={index}
            id={device.id}
            deviceNumber={device.pan}
            name={device.name}
            status={device.status}
            type={device.type}
          />
        ))}
      </BoxesWrapper>
      <OverviewHeader
        boldInfo={t('contacts')}
        actions={{ label: t('allContacts'), to: '/contacts' }}
      />
      <BoxesWrapper>
        {data?.contacts &&
          data?.contacts?.length > 0 &&
          data?.contacts.map((contact, index) => (
            <ContactWhiteBox key={index}>
              <ContactBox
                id={contact.id}
                name={contact.name}
                phone={contact.phone}
                email={contact.email}
                logo={contact.logo}
                key={index}
              />
            </ContactWhiteBox>
          ))}
      </BoxesWrapper>
    </div>
  );
};

export default Dashboard;
