import AuthenticatedLayout from 'components/layouts/authenticatedLayout';
import NotAuthenticatedLayout from 'components/layouts/notAuthenticatedLayout';
import ProtectedRoute from 'components/protectedRoute';
import { Route, Routes } from 'react-router';
import {
  CONTACTS_URL,
  CONTACT_DETAIL_URL,
  DEVICES_URL,
  FAQ_URL,
  HELP_URL,
  LOADING_URL,
  LOGIN_URL,
  RIDES_URL,
  ROOT_URL,
  DEVICES_DETAIL_URL,
  DOWNLOAD_RECEIPT_OR_INVOICE_PAY_WINDOW,
  DOWNLOAD_RECEIPT_OR_INVOICE_JOURNEY,
  DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS,
  DOWNLOAD_RECEIPT_OR_INVOICE_ERROR,
  DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE,
  HEALTH_CHECK_URL,
} from './appRoutesConst';
import ContactDetail from './contactDetail';
import Contacts from './contacts';
import Dashboard from './dashBoard';
import DevicesPage from './devicesPage';
import Faq from './faq';
import HelpPage from './helpPage';
import LoadingPage from './loadingPage';
import Login from './login';
import RidesPage from './ridesPage';
import DeviceDetailPage from './deviceDetailPage';
import DownloadReceiptOrInvoicePage from './downloadReceiptOrInvoicePage';
import SuccessPage from './successPage';
import DuplicatePage from './duplicatePage';
import ErrorPage from './errorPage';
import HealthCheck from './healthCheck';

const AppRoutes = () => {
  return (
    <Routes>
      {/* AUTHENTICATED ROUTES */}
      <Route element={<AuthenticatedLayout />}>
        <Route
          path={ROOT_URL}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={RIDES_URL}
          element={
            <ProtectedRoute>
              <RidesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={FAQ_URL}
          element={
            <ProtectedRoute>
              <Faq />
            </ProtectedRoute>
          }
        />
        <Route
          path={CONTACTS_URL}
          element={
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          }
        />
        <Route
          path={HELP_URL}
          element={
            <ProtectedRoute>
              <HelpPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={DEVICES_URL}
          element={
            <ProtectedRoute>
              <DevicesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${DEVICES_DETAIL_URL}/:id`}
          element={
            <ProtectedRoute>
              <DeviceDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${CONTACT_DETAIL_URL}/:id`}
          element={
            <ProtectedRoute>
              <ContactDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${DOWNLOAD_RECEIPT_OR_INVOICE_PAY_WINDOW}/:payWindowId`}
          element={
            <ProtectedRoute>
              <DownloadReceiptOrInvoicePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${DOWNLOAD_RECEIPT_OR_INVOICE_JOURNEY}/:journeyId`}
          element={
            <ProtectedRoute>
              <DownloadReceiptOrInvoicePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={DOWNLOAD_RECEIPT_OR_INVOICE_SUCCESS}
          element={
            <ProtectedRoute>
              <SuccessPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={DOWNLOAD_RECEIPT_OR_INVOICE_DUPLICATE}
          element={
            <ProtectedRoute>
              <DuplicatePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={DOWNLOAD_RECEIPT_OR_INVOICE_ERROR}
          element={
            <ProtectedRoute>
              <ErrorPage />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* NOT AUTHENTICATED ROUTES */}
      <Route element={<NotAuthenticatedLayout />}>
        <Route path={`${LOGIN_URL}`} element={<Login />} />
      </Route>
      <Route path={`${LOADING_URL}`} element={<LoadingPage />} />
      <Route path={`${HEALTH_CHECK_URL}`} element={<HealthCheck />} />
    </Routes>
  );
};

export default AppRoutes;
