import { InputBase, styled } from '@mui/material';

const AppInputBase = styled(InputBase)(
  ({ theme: { palette, spacing } }) => ({
    '& .MuiInputBase-input': {
      color: palette.text.darkBlue,
      borderRadius: '8px',
      backgroundColor: 'white',
      border: `1px solid ${palette.border.darkGrey}`,
      padding: spacing(2, 2),
      '&:focus': {
        outline: 0,
        borderColor: palette.border.secondary,
      },
      '&:hover': {
        borderColor: palette.border.secondary,
      },
    },
    '&.MuiInputBase-root.Mui-error': {
      '& .MuiInputBase-input': {
        borderColor: palette.error.main,
      },
    },
  }),
);

export default AppInputBase;
