import {
  CircularProgress,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { InputVariant } from 'types/appEnums';
import FormField from '../formField';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark_success.svg';

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    prop !== 'inputVariant' &&
    prop !== 'loading' &&
    prop !== 'success' &&
    prop !== 'fontWeight',
})<{
  inputVariant?: InputVariant;
  loading?: boolean;
  success?: boolean;
  fontWeight: number;
}>(
  ({
    inputVariant,
    loading,
    success,
    fontWeight,
    theme: { palette, spacing },
  }) => ({
    '& .MuiInputBase-root': {
      color: palette.text.darkBlue,
      padding: spacing(
        inputVariant === InputVariant.LIGHT ? 1.5 : 2, // top
        loading || success ? 7 : 2, // right
        inputVariant === InputVariant.LIGHT ? 1.5 : 2, // bottom
        2, // left
      ),
      borderRadius: '8px',
      backgroundColor: 'white',
      fontSize: '0.875rem',
      fontWeight,

      '& input': {
        padding: 0,
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
      borderColor: COLORS.LIGHT_GREY.DARK,
    },
    '& .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.LIGHT_GREY.DARK,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border.darkGrey,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: palette.border.secondary,
      },
    '& .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: COLORS.LIGHT_GREY.DARK,
      },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: palette.border.secondary,
        borderWidth: '1px',
      },
  }),
);

const FieldWrapper = styled('div')(() => ({
  position: 'relative',
}));

const LoadingIcon = styled(CircularProgress)(
  ({ theme: { spacing } }) => ({
    position: 'absolute',
    right: spacing(2),
    top: '25%',
  }),
);

const SuccessIconWrapper = styled('div')(
  ({ theme: { spacing } }) => ({
    position: 'absolute',
    right: spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
    height: 20,
    // color: COLORS.LIGHT_GREEN.MAIN,
  }),
);

export type Props = {
  errorText?: string;
  inputVariant?: InputVariant;
  loading?: boolean;
  success?: boolean;
  fontWeight?: number;
  requiredSpecialText?: string;
} & TextFieldProps;

const AppTextField = ({
  label,
  error,
  errorText,
  required,
  inputVariant,
  disabled,
  loading,
  success,
  fontWeight = 500,
  requiredSpecialText,
  ...rest
}: Props) => {
  return (
    <FormField
      inputVariant={inputVariant}
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      disabled={disabled}
      requiredSpecialText={requiredSpecialText}
    >
      <FieldWrapper>
        <StyledTextField
          fullWidth
          error={error}
          inputVariant={inputVariant}
          disabled={disabled}
          loading={loading}
          success={success}
          fontWeight={fontWeight}
          {...rest}
        />
        {loading && (
          <LoadingIcon
            sx={{
              '&.MuiCircularProgress-root': {
                color: COLORS.LIGHT_BLUE.DARK,
              },
            }}
            size={24}
          />
        )}
        {!loading && success && (
          <SuccessIconWrapper>
            <SuccessIcon />
          </SuccessIconWrapper>
        )}
      </FieldWrapper>
    </FormField>
  );
};

export default AppTextField;
