import {
  IconButton,
  Menu,
  MenuItem,
  SvgIconTypeMap,
} from '@mui/material';
import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface MenuItemInterface {
  label: string;
  icon?: OverridableComponent<
    SvgIconTypeMap<Record<string, never>, 'svg'>
  >;
  onClick: () => void;
}

interface ActionButtonProps {
  actionButtonIcon?: React.ReactNode;
  menuItems?: (MenuItemInterface | JSX.Element)[];
}

// Button which offers action menu on press
const ActionButton: React.FC<ActionButtonProps> = ({
  actionButtonIcon,
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {menuItems && menuItems?.length > 0 ? (
        <>
          <IconButton onClick={handleClick}>
            {actionButtonIcon ? actionButtonIcon : <MoreHorizIcon />}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
          >
            {menuItems?.map((item, index) => {
              if ('label' in item)
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      item.onClick();
                      handleClose();
                    }}
                  >
                    {item.label}
                  </MenuItem>
                );
              if (
                typeof item !== 'string' &&
                typeof item !== 'number'
              ) {
                return <MenuItem key={index}>{item}</MenuItem>;
              }
            })}
          </Menu>
        </>
      ) : null}
    </>
  );
};

export default ActionButton;
