import { Typography, useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface NoticeIconProps {
  label: string;
  type: 'error' | 'success' | 'info';
}

export const NoticeIcon: React.FC<NoticeIconProps> = ({
  label,
  type,
}) => {
  const theme = useTheme();

  let icon = null;
  let color = null;

  switch (type) {
    case 'error':
      icon = <WarningIcon />;
      color = theme.palette.error.main;
      break;
    case 'success':
      icon = <CheckCircleIcon />;
      color = theme.palette.success.main;
      break;
    case 'info':
      icon = <AccessTimeIcon />;
      color = theme.palette.info.main;
      break;
    default:
      break;
  }

  return (
    <Typography
      variant="subtitle1"
      fontWeight="bold"
      component="span"
      sx={{
        display: 'flex',
        color: color,
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'middle',
        gap: 1,
      }}
    >
      {icon}
      {label}
    </Typography>
  );
};
