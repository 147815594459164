import axios from 'axios';

export enum EVENTS {
  UNAUTHORIZED = '401',
  SERVER_ERROR = 'serverError',
  UNKNOWN_ERROR = 'unknownError',
}

export interface EventDetail {
  name: string;
}

export const dispatchEvent = (name: EVENTS) => {
  const createdEvent = new CustomEvent<EventDetail>('build', {
    detail: { name },
  });
  document.dispatchEvent(createdEvent);
};

export const errorHandling = async (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.status === 401) {
      dispatchEvent(EVENTS['UNAUTHORIZED']);
    }
    if (
      error?.response?.status &&
      error?.response?.status >= 500 &&
      error?.response?.status < 600
    ) {
      dispatchEvent(EVENTS['SERVER_ERROR']);
    }
    // do something
    // or just re-throw the error
    throw error;
  } else {
    // do something else
    // or creating a new error
    dispatchEvent(EVENTS['UNKNOWN_ERROR']);
    // throw new Error('different error than axios');
  }
};
