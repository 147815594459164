import {
  Container,
  IconButton,
  Typography,
  Box,
  useTheme,
  styled,
  Collapse,
} from '@mui/material';
import { Outlet, useLocation } from 'react-router';
import {
  CONTACTS_URL,
  DEVICES_URL,
  FAQ_URL,
  HELP_URL,
  RIDES_URL,
  ROOT_URL,
} from 'pages/appRoutesConst';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useLanguageSelect from 'utils/hooks/useLanguageSelect';
import logo from 'assets/images/switchio_blue_small.svg';
import AppLink from 'components/appLink/appLink';
import LogoutIcon from '@mui/icons-material/Logout';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import useAuthentication from 'utils/hooks/useAuthentication';
import Footer from './footer';

type NavButtonProps = { isActive: boolean };

const NavBar = styled('div')(({ theme: { spacing, palette } }) => ({
  width: '100%',
  position: 'sticky',
  top: 0,
  padding: spacing(2, 0),
  backgroundColor: palette.common.white,
  zIndex: 10,
}));

const NavItemsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(2),
  flexWrap: 'wrap',
}));

const NavButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<NavButtonProps>(({ theme: { palette }, isActive }) => ({
  ':first-letter': {
    textTransform: 'capitalize',
  },
  color: isActive
    ? palette.text.lightGrey.dark
    : palette.text.lightGrey.main,
  textDecoration: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: palette.text.lightGrey.dark,
  },
  '&::after': {
    content: "''",
    height: '2px',
    width: isActive ? '100%' : '0%',
    backgroundColor: palette.text.lightGrey.dark,
    display: 'block',
    zIndex: 10,
    transition: 'all 0.3s ease-in-out',
  },
  ':hover': {
    '&::after': {
      width: '100%',
    },
  },
}));

const AppWrapper = styled('div')(({ theme: { palette } }) => ({
  minHeight: '100vh',
  backgroundColor: palette.background.lightGrey.main,
  display: 'flex',
  flexDirection: 'column',
}));

const MobileMenuWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'displayMenu',
})<{ displayMenu: boolean }>(({ theme: { palette, spacing } }) => ({
  position: 'fixed',
  top: 0,
  width: '100%',
  marginTop: spacing(8),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.background.paper,
}));

const MobileNavlinksWrapper = styled('div')(
  ({ theme: { palette, spacing } }) => ({
    padding: spacing(4, 2),
    borderTop: `1px solid ${palette.border.darkGrey}`,
    borderBottom: `1px solid ${palette.border.darkGrey}`,
  }),
);

const MobileAdditionalNavItems = styled('div')(
  ({ theme: { spacing } }) => ({
    padding: spacing(4, 2),
  }),
);

const MobileNavButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<NavButtonProps>(({ theme: { palette }, isActive }) => ({
  ':first-letter': {
    textTransform: 'capitalize',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: isActive
    ? palette.text.lightGrey.dark
    : palette.text.lightGrey.main,
  textDecoration: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: palette.text.lightGrey.dark,
  },
}));

const OutletWrapper = styled(Container)(({ theme: { spacing } }) => ({
  marginTop: spacing(5),
  height: '100%',
  paddingBottom: spacing(5),
}));

const TitleLogo = styled(AppLink)(({ theme: { spacing } }) => ({
  paddingRight: spacing(4),
}));

const TitleLanguageBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: spacing(4),
}));

// Define navbar menu items here
const NAVBAR_FIELDS: { url: string; label: string }[] = [
  {
    url: ROOT_URL,
    label: 'home',
  },
  {
    url: RIDES_URL,
    label: 'rides',
  },
  {
    url: DEVICES_URL,
    label: 'devices',
  },
  {
    url: FAQ_URL,
    label: 'faq',
  },
  {
    url: HELP_URL,
    label: 'help',
  },
  {
    url: CONTACTS_URL,
    label: 'contacts',
  },
];
interface MobileMenuProps {
  isMobile: boolean;
  isMobileMenuOpen: boolean;
  languageSelect: JSX.Element;
}

const MobileNavigation: React.FC<MobileMenuProps> = ({
  isMobile,
  isMobileMenuOpen,
  languageSelect,
}) => {
  const { t } = useTranslation('common');
  const { logOut } = useAuthentication();
  return (
    <Collapse in={isMobile && isMobileMenuOpen}>
      <MobileNavlinksWrapper>
        {NAVBAR_FIELDS.map((field, index) => (
          <div key={index}>
            <NavLink
              to={`${field.url}`}
              style={{ textDecoration: 'none' }}
            >
              {({ isActive }) => (
                <MobileNavButton isActive={isActive}>
                  <Typography>
                    {t(`navigation.${field.label}`)}
                  </Typography>
                </MobileNavButton>
              )}
            </NavLink>
          </div>
        ))}
      </MobileNavlinksWrapper>
      <MobileAdditionalNavItems>
        <MobileNavButton isActive={false}>
          {languageSelect}
        </MobileNavButton>
        <MobileNavButton
          onClick={async () => {
            await logOut();
          }}
          isActive={false}
        >
          {t(`navigation.logout`)}
          <IconButton>
            <LogoutIcon />
          </IconButton>
        </MobileNavButton>
      </MobileAdditionalNavItems>
    </Collapse>
  );
};

interface StandardNavigationProps {
  languageSelect: JSX.Element;
}

const StandardNavigation: React.FC<StandardNavigationProps> = ({
  languageSelect,
}) => {
  const { t } = useTranslation('common');
  const { logOut, checkTokenAndRefresh } = useAuthentication();

  useEffect(() => {
    const intervalID = setInterval(() => {
      checkTokenAndRefresh();
    }, 60 * 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <>
      <NavItemsWrapper>
        {NAVBAR_FIELDS.map((field, index) => (
          <div key={index}>
            <NavLink
              to={`${field.url}`}
              style={{ textDecoration: 'none' }}
            >
              {({ isActive }) => (
                <NavButton isActive={isActive}>
                  <Typography>
                    {t(`navigation.${field.label}`)}
                  </Typography>
                </NavButton>
              )}
            </NavLink>
          </div>
        ))}
      </NavItemsWrapper>
      <TitleLanguageBox>
        {languageSelect}
        <Box sx={{ marginLeft: 2 }}>
          <Typography
            onClick={async () => {
              await logOut();
            }}
            style={{ cursor: 'pointer' }}
          >
            {t(`navigation.logout`)}
            <IconButton>
              <LogoutIcon />
            </IconButton>
          </Typography>
        </Box>
      </TitleLanguageBox>
    </>
  );
};

const AuthenticatedLayout: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const languageSelect = useLanguageSelect({ isMobile });

  const location = useLocation();

  // On mobile, close menu when redirected to new app page
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  return (
    <AppWrapper>
      <NavBar>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <div>
            <TitleLogo to="/">
              <img src={logo} />
            </TitleLogo>
          </div>
          {isMobile ? (
            <div>
              <IconButton
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </div>
          ) : (
            <StandardNavigation languageSelect={languageSelect} />
          )}
        </Container>
        <MobileMenuWrapper displayMenu={isMobile && isMobileMenuOpen}>
          <MobileNavigation
            languageSelect={languageSelect}
            isMobile={isMobile}
            isMobileMenuOpen={isMobileMenuOpen}
          />
        </MobileMenuWrapper>
      </NavBar>
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
      <Footer />
    </AppWrapper>
  );
};

export default AuthenticatedLayout;
