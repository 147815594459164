import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark_circle.svg';
import AppButton from 'components/appButton';
import { COLORS } from 'theme/colors';
import { useNavigate } from 'react-router';
import { RIDES_URL } from './appRoutesConst';

const Wrapper = styled('div')(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    marginTop: spacing(5),
    padding: spacing(7.5, 9, 11, 9),
    background: palette.common.white,
    borderRadius: 8,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: spacing(4),
    },
  }),
);

const IconWrapper = styled('span')(
  ({ theme: { spacing, breakpoints } }) => ({
    paddingTop: spacing(6.5),
    paddingBottom: spacing(9),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(4),
    },
  }),
);

const Title = styled(Typography)(() => ({
  fontSize: '2.5rem',
}));

const Description = styled(Typography)(({ theme: { spacing } }) => ({
  color: COLORS.GREY.MAIN,
  marginTop: spacing(3),
  marginBottom: spacing(7.5),
}));

const SuccessPage = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.success',
  });
  const navigate = useNavigate();

  return (
    <Wrapper>
      <IconWrapper>
        <SuccessIcon />
      </IconWrapper>
      <Title variant="h2">{t('title')}</Title>
      <Description variant="body1">{t('desc')}</Description>
      <AppButton
        variant="contained"
        color="secondary"
        onClick={() => navigate(RIDES_URL)}
      >
        {t('button')}
      </AppButton>
    </Wrapper>
  );
};

export default SuccessPage;
