import axios from 'axios';
import { loadFromLocalStorage } from 'localStorage';
import { AUTH_DATA } from 'localStorage/localStorageKeys';
import { TokenResponse } from './authenticationApi';

export const APP_URL = `${process.env.REACT_APP_API_KEY}/api/${process.env.REACT_APP_API_VERSION}`;

const apiConfig = {
  baseURL: APP_URL,
};

const api = axios.create({ ...apiConfig });
api.defaults.headers.post['Content-Type'] =
  'application/json;charset=utf-8';

const notAuthenticatedApi = axios.create({ ...apiConfig });
notAuthenticatedApi.defaults.headers.post['Content-Type'] =
  'application/json;charset=utf-8';

api.interceptors.request.use(
  (config) => {
    const loginData: TokenResponse = {
      ...JSON.parse(
        loadFromLocalStorage({
          key: AUTH_DATA,
        }),
      ),
    };
    const { token } = loginData;
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { api, notAuthenticatedApi };
